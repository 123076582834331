import { Table, TableColumnType } from 'antd';
import PropTypes from 'prop-types';

interface BalanceItem {
  codigo: string;
  descricao: string;
  saldo: number;
}

interface TablePieProps {
  balance: BalanceItem[]
}

const TablePie: React.FC<TablePieProps> = ({balance}) => {
  const configClassName = (record: BalanceItem) => 
    record.saldo >= 0 ? "cor-positiva" : "cor-negativa";

  const columns: TableColumnType<BalanceItem>[] = [
    {
        title: 'Codigo',
        dataIndex: 'codigo',
        key: 'codigo'
    },
    {
        title: 'Descrição',
        dataIndex: 'descricao',
        key: 'descricao'
    },
    {
        title: 'Saldo',
        dataIndex: 'saldo',
        key: 'saldo',
        align: 'right',
        render: (text: string, record: BalanceItem) => (
          <span>{record.saldo.toFixed(2)}</span>
        )
    },
  ]

  return(
    <Table size="small" rowClassName={configClassName} columns={columns} dataSource={balance.filter(item => item.saldo !== 0)} pagination={false}/>          
  )
}

TablePie.propTypes = {
  balance: PropTypes.any.isRequired
}

export default TablePie