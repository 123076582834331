/* eslint-disable no-constant-condition */
import { notification } from "antd";
import axios from "axios";

const instance = axios.create({    
    baseURL: false ? "http://localhost:55361" : "https://api.financial.rsouza.dev",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    },
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');

    if (token !== null){
        config.headers.Authorization = "Bearer " + token;
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(async (response) => {    
    if (response.data && response.data.messages) {
        const message = response.data.messages.join("\n");

        if (message) {
            notification.success({
                message: "Finpes",
                description: message
            });
        }
    }

    return response;  
}, function (error) { 
    if (error.code === "ERR_NETWORK"){
        window.location.href = '/login';
        // TODO: redirecionar para uma tela informando que estamos passando por manutenção.       
    }
    if (error.response){
        const messages = error.response.data.messages || "Ocorreu um erro desconhecido.";

        if ([401, 403].includes(error.response.status)) {            
            localStorage.removeItem('token');
            notification.error({
                message: "Autenticação",
                description: messages
            });
            console.log(messages);
            window.location.href = '/login';
        } else if (error.response.status === 422) {       
            notification.error({
                message: "Informação",
                description: messages
            });
        } else if (error.response.status === 500) {            
            notification.error({
                message: "Erro Interno",
                description: messages
            });
        } else {
            notification.error({
                message: "Erro",
                description: messages
            });
        }    
    } else {
        notification.error({
            message: "Erro Desconhecido",
            description: "Ocorreu um erro desconhecido. Por favor, tente novamente mais tarde."
        });
    }
    
    return Promise.reject(error);
})

export default instance;