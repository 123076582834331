import { ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Space, Switch, Tag } from "antd";
import dayjs from "dayjs";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { getSaldoDespesa, getSaldoReceita } from "../../shared/services/api";

interface SaldoProps {
  contaCorrenteId: number;
  competencia: Date;
  refreshKey: boolean;
  key: number;
}

const Saldo: React.FC<SaldoProps> = ({contaCorrenteId, competencia, refreshKey, key}) => {
  const [despesa, setDespesa] = useState<number>(0);
  const [receita, setReceita] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [isProvisao, setIsProvisao] = useState(false);

  useEffect(() => {
    fetchDespesa();
    fetchReceita();    
  }, [isProvisao, receita, despesa, refreshKey, key]);

  useEffect(() => {
    fetchTotal();
  }, [receita, despesa, key]);

  const fetchReceita = async () => {
    const response = await getSaldoReceita({
      contaCorrenteId: contaCorrenteId,
      competencia: dayjs(competencia).startOf('month').toDate(),
      provisao: isProvisao
    });

    setReceita(response.data.data.valor)
  }  

  const fetchDespesa = async () => {
    const response = await getSaldoDespesa({
      contaCorrenteId: contaCorrenteId,
      competencia: dayjs(competencia).startOf('month').toDate(),
      provisao: isProvisao
    });

    setDespesa(response.data.data.valor)
  }

  const fetchTotal = async () => {
    setTotal(receita + despesa);
  }

  const onChange = (checked: boolean) => {
    setIsProvisao(checked);
  }

  return (
    <>
      
      <Space>
        <Switch title="Provisão?" onChange={onChange} checkedChildren='Provisão' unCheckedChildren='Pago'/>
        <Tag icon={<ArrowUpOutlined />} color='green' title='Receita'>{`Receita: ${receita.toFixed(2)}`}</Tag>
        <Tag icon={<ArrowDownOutlined />} color='red' title='Despesa'>{`Despesa: ${despesa.toFixed(2)}`}</Tag>
        <Tag icon={<ArrowRightOutlined />} color='gray' title='Total'>{`Total: ${total.toFixed(2)}`}</Tag>
      </Space>
    </>
  )
}

Saldo.propTypes = {
  contaCorrenteId: PropTypes.number.isRequired,
  competencia: PropTypes.instanceOf(Date).isRequired,
  refreshKey: PropTypes.bool.isRequired,
  key: PropTypes.number.isRequired,
};

export default Saldo