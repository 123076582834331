import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip
} from 'chart.js';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

ChartJS.register(
  ArcElement, Tooltip, Legend
);

interface BalanceItem {
  codigo: string;
  descricao: string;
  saldo: number;
}

interface charBarProps {
  balance: BalanceItem[]
}

const ChartPie: React.FC<charBarProps> = ({balance}) => {
  const labels = balance.filter(item => item.saldo !== 0).map(item => `${item.descricao}`);  
  
  const data = {
    labels: labels,
    datasets: [
      {
        data: balance.filter(item => item.saldo !== 0).map(item => item.saldo.toFixed(2)),
        backgroundColor: balance.filter(item => item.saldo !== 0).map(item => {
          return item.saldo >= 0 ? '#236ecf' : '#ff6347';
        }),
      }
    ],
  };

  return (
    <Pie data={data} />
  )
};

ChartPie.propTypes = {
  balance: PropTypes.any.isRequired
}

export default ChartPie;
