import { useState } from 'react';

const FaixaDestaque = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className='faixa-destaque'>
          <a href='/UserComparison'>
            Torne-se um cliente PRO e tenha acessos exclusivos.
          </a>
          <button className='fechar' onClick={handleClose}>
            X
          </button>
        </div>
      )}
    </>
  );
};

export default FaixaDestaque;
