import {
  FolderOpenOutlined,
  FundOutlined,
  LogoutOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Saudacao from '../../components/saldacao/Saudacao';
import { getPessoa, logout } from '../../shared/services/api';
import Dashboard from '../Dashboard';
import Movimento from '../Movimento';
import MovimentoEmAberto from '../MovimentoEmAberto';
import Dfc from '../Dfc';
import Moeda from '../Moeda';
import Banco from '../Banco';
import Agencia from '../Agencia';
import ContaCorrente from '../ContaCorrente';
import PlanAccount from '../PlanoConta';
import CartaoCredito from '../CartaoCredito';
import Perfil from '../Perfil';
import FaixaDestaque from './faixaDestaque';

const { Header, Content, Footer } = Layout;

const items = [
  { label: 'Dashboard', key: '0', icon: <FundOutlined /> },
  { label: 'Lançamentos', key: '1', icon: <FolderOpenOutlined /> },
  { label: 'Em Aberto', key: '11', icon: <FolderOpenOutlined /> },
  { label: 'DFC', key: '2', icon: <PieChartOutlined /> },  
  { label: 'Sair', key: '10', icon: <LogoutOutlined /> },
];

const submenuItems = [  
  { label: 'Moeda', key: '3', icon: <UnorderedListOutlined /> },
  { label: 'Banco', key: '4', icon: <UnorderedListOutlined /> },
  { label: 'Agencia', key: '5', icon: <UnorderedListOutlined /> },
  { label: 'Conta Corrente', key: '6', icon: <UnorderedListOutlined /> },
  { label: 'Plano de Conta', key: '7', icon: <UnorderedListOutlined /> },
  { label: 'Cartão de Crédito', key: '8', icon: <UnorderedListOutlined /> },
  { label: 'Perfil', key: '9', icon: <UserOutlined /> },
];

const Welcome: React.FC = () => {    
  const [currentScreen, setCurrentScreen] = useState('0');
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const navigate = useNavigate();
  const [tipoPerfil, setTipoPerfil] = useState('Gratis');

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    const response = await getPessoa();
    setTipoPerfil(response.data.data.tipoPessoa);
  }

  const handleClick = (key: string) => {
    if (key === '10'){      
      logout();
      navigate('/login');
    }
    setCurrentScreen(key);
    setSubmenuVisible(false); // Close submenu when an item is clicked
  }

  const toggleSubmenu = () => {
    setSubmenuVisible(!submenuVisible);
  }

  return (
    <Layout>
      <Header className="header">
        <div className="logo">
          <Avatar className="avatar" size={50} src={<img src={"../FinPes.png"} alt="avatar"/>} />
        </div>
        <Menu 
          theme="dark" 
          mode="horizontal" 
          defaultSelectedKeys={['0']} 
          selectedKeys={[currentScreen]} 
          onClick={(e) => handleClick(e.key)} 
          items={items} 
          className="menu"
        />
      </Header>
      <button className="submenu-button" onClick={toggleSubmenu}>
        More Options
      </button>
      {submenuVisible && (
        <div className="submenu-content">
          {submenuItems.map(item => (
            <div key={item.key} className="submenu-item" onClick={() => handleClick(item.key)}>
              {item.icon} {item.label}
            </div>
          ))}
        </div>
      )}
      <Layout>        
        <Content style={{ padding: '5px', minHeight: 'calc(100vh - 60px)', overflowY: 'auto' }}>
          <div style={{ marginBottom: '20px' }}>
            <Saudacao/>
            {tipoPerfil === 'Gratis' && <FaixaDestaque/>}
          </div>
          <div style={{padding: 1, minHeight: 'auto'}}>
            {currentScreen === '0' && <Dashboard/>}
            {currentScreen === '1' && <Movimento/>}
            {currentScreen === '11' && <MovimentoEmAberto/>}
            {currentScreen === '2' && <Dfc/>}
            {currentScreen === '3' && <Moeda/>}
            {currentScreen === '4' && <Banco/>}
            {currentScreen === '5' && <Agencia/>}
            {currentScreen === '6' && <ContaCorrente/>}
            {currentScreen === '7' && <PlanAccount/>}
            {currentScreen === '8' && <CartaoCredito/>}
            {currentScreen === '9' && <Perfil/>}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <p>©2023 Created by rsouza.dev</p>
        </Footer>
      </Layout>
      {/* Bottom Menu for Mobile */}
      <div className="bottom-menu">
        {items.map(item => (
          <div key={item.key} className="menu-item" onClick={() => handleClick(item.key)}>
            {item.icon}
            <span>{item.label}</span>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Welcome;