import { Checkbox, Modal, Typography, notification } from 'antd';
import { useState } from 'react';

const { Title, Paragraph, Text } = Typography;

interface TermsProps {
  isModalVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

const TermsOfUseModal = ({ isModalVisible, handleOk, handleCancel }: TermsProps) => {
    const [agreed, setAgreed] = useState(false);

    const handleOkClick = () => {
        if (agreed) {
        handleOk();
        } else {
        notification.info({
            message: 'Info',
            description: 'Você deve concordar com os termos para continuar.',
        });
        }
    };
  return (
    <Modal
        title="Termos de Uso"
        visible={isModalVisible}
        onOk={handleOkClick}
        onCancel={handleCancel}
        closable={false} // Remove o botão de fechar no canto superior direito
        width={800}
        bodyStyle={{ maxHeight: '70vh', overflowY: 'auto' }}
        maskClosable={false}
    >
        <Typography>
        <Title level={2}>1. Introdução</Title>
        <Paragraph>
            Bem-vindo ao Finpes! Ao acessar e usar o site Finpes (https://financial.rsouza.dev/), você concorda com os presentes Termos de Uso. Caso não concorde com algum dos termos aqui descritos, recomendamos que não utilize o site. O Finpes é um serviço oferecido pela RS Consultoria, inscrita no CNPJ 36.377.673/0001-02.
        </Paragraph>
        <Title level={2}>2. Definições</Title>
        <Paragraph>
            <Text strong>Usuário:</Text> Qualquer pessoa que acessar o site.<br />
            <Text strong>Finpes:</Text> O site e seus proprietários, representados pela RS Consultoria.<br />
            <Text strong>LGPD:</Text> Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018).
        </Paragraph>
        <Title level={2}>3. Uso do Site</Title>
        <Paragraph>
            <Text strong>3.1.</Text> A RS Consultoria se compromete a manter o site Finpes em funcionamento 24 horas por dia, 7 dias por semana, salvo períodos de manutenção que serão previamente comunicados aos usuários.<br />
            <Text strong>3.2.</Text> O usuário concorda em utilizar o site de maneira ética e legal, respeitando todas as leis e regulamentações aplicáveis.
        </Paragraph>
        <Title level={2}>4. Privacidade e Proteção de Dados</Title>
        <Paragraph>
            <Text strong>4.1.</Text> A RS Consultoria está comprometida com a proteção dos dados pessoais dos usuários, em conformidade com a LGPD.<br />
            <Text strong>4.2. Coleta de Dados:</Text> Coletamos informações pessoais fornecidas pelo usuário ao utilizar o site, como nome, e-mail e outras informações necessárias para a prestação de nossos serviços.<br />
            <Text strong>4.3. Uso dos Dados:</Text> Os dados coletados serão utilizados apenas para os fins específicos para os quais foram fornecidos, como o fornecimento de serviços e comunicação com o usuário.<br />
            <Text strong>4.4. Compartilhamento de Dados:</Text> A RS Consultoria não compartilha dados pessoais dos usuários com terceiros, exceto quando necessário para cumprir obrigações legais ou quando expressamente autorizado pelo usuário.<br />
            <Text strong>4.5. Segurança dos Dados:</Text> Implementamos medidas de segurança adequadas para proteger os dados pessoais dos usuários contra acesso não autorizado, alteração, divulgação ou destruição.<br />
            <Text strong>4.6. Direitos dos Titulares:</Text> Os usuários têm o direito de acessar, corrigir, excluir ou portar seus dados pessoais, bem como de solicitar a limitação do processamento de seus dados. Para exercer esses direitos, o usuário deve entrar em contato conosco através do e-mail suporte@rsouza.dev.
        </Paragraph>
        <Title level={2}>5. Responsabilidades</Title>
        <Paragraph>
            <Text strong>5.1.</Text> A RS Consultoria não se responsabiliza por eventuais indisponibilidades do site causadas por motivos de força maior, como falhas técnicas ou problemas de conexão.<br />
            <Text strong>5.2.</Text> O usuário é responsável pela veracidade das informações fornecidas e pelo uso adequado do site.
        </Paragraph>
        <Title level={2}>6. Modificações nos Termos de Uso</Title>
        <Paragraph>
            <Text strong>6.1.</Text> A RS Consultoria se reserva o direito de modificar os Termos de Uso a qualquer momento. As alterações serão comunicadas aos usuários através do site.
        </Paragraph>
        <Title level={2}>7. Contato</Title>
        <Paragraph>
            Para quaisquer dúvidas ou solicitações relacionadas aos Termos de Uso ou à Política de Privacidade, entre em contato conosco pelo e-mail suporte@rsouza.dev.
        </Paragraph>
        <Title level={2}>8. Legislação Aplicável e Foro</Title>
        <Paragraph>
            Estes Termos de Uso são regidos pela legislação brasileira. Qualquer disputa decorrente do uso do site será dirimida no foro da comarca de Rio de Janeiro, com renúncia a qualquer outro, por mais privilegiado que seja.
        </Paragraph>
        </Typography>
        <Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)}>
            Eu li e concordo com os termos de uso
        </Checkbox>
    </Modal>
    )
}

export default TermsOfUseModal;
