import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { deleteMoeda, getMoedas, postMoeda, putMoeda } from "../../shared/services/api";
import { Currency } from "./interfaces/Currency";
import TableMoeda from "./tableMoeda";

const Moeda = () => {
  const [data, setData] = useState<Currency[]>([]);

  useEffect(() => {
    fetchMoedas();
  }, []);

  const fetchMoedas = async () => {
    const response = await getMoedas();
    setData(response.data.data);
  }

  const handleDelete = async (moedaId: number) => {   
    await deleteMoeda(moedaId);
    fetchMoedas();
  };

  const handleUpdate = async (updatedData: Currency) => {    
    await putMoeda(updatedData);
    fetchMoedas();
  };

  const handleCreate = async (newData: Currency) => {
    await postMoeda(newData);
    fetchMoedas();
  };

  return (
    <Content className="content"> 
      <h1>Moedas</h1>      
      <TableMoeda data={data} onDelete={handleDelete} onUpdate={handleUpdate} onCreate={handleCreate} />      
    </Content> 
  )
}

export default Moeda