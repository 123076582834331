import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { deleteBanco, getBancos, postBanco, putBanco } from "../../shared/services/api";
import { Bank } from "./interfaces/Bank";
import TableBanco from "./tableBanco";

const Banco = () => {
  const [data, setData] = useState<Bank[]>([]);

  useEffect(() => {
    fetchBancos();
  }, []);

  const fetchBancos = async () => {
    const response = await getBancos();
    setData(response.data.data);
  }

  const handleDelete = async (moedaId: number) => {   
    await deleteBanco(moedaId);
    fetchBancos();
  };

  const handleUpdate = async (updatedData: Bank) => {    
    await putBanco(updatedData);
    fetchBancos();
  };

  const handleCreate = async (newData: Bank) => {
    await postBanco(newData);
    fetchBancos();
  };

  return (
    <Content className="content"> 
      <h1>Bancos</h1>      
      <TableBanco data={data} onDelete={handleDelete} onUpdate={handleUpdate} onCreate={handleCreate} />      
    </Content>
  )
}

export default Banco