import { Content } from "antd/es/layout/layout";
import TableContaCorrente from "./tableContaCorrente";

const ContaCorrente = () => {  
  return (
    <Content className="content"> 
      <h1>Conta Corrente</h1>      
      <TableContaCorrente/>      
    </Content> 
  )
}

export default ContaCorrente