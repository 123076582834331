/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePicker, Select, Space, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import CurrencyFormat from "../../components/tools/currency";
import { getContasCorrentes, getSaldoAnual } from "../../shared/services/api";

const Dfc: React.FC = () => {
  const [accountId, setSelectedAccount] = useState(0);
  const [competencia, setCompetencia] = useState(dayjs(new Date()).startOf('month'));
  const [listaContaCorrente, setListaContaCorrente] = useState([]);
  const [listaSaldos, setListaSaldos] = useState([]);

  useEffect(() => {
    if (accountId && competencia){
      fetchData();
    }
  }, [accountId, competencia]);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchData = async () => {
    const response = await getSaldoAnual({ contaCorrenteId: accountId, competencia: competencia.toDate(), provisao: true});
    setListaSaldos(response.data.data);
  };

  const fetchAccounts = async () => {
    const response = await getContasCorrentes(false);

    const contas: any = []
    response.data.data.map((conta: any) => {
      contas.push({
        value: conta.id,
        label: `${conta.numero} - ${conta.descricao}`,
      })
    })
    setListaContaCorrente(contas);
  }

  const handleAccountChange = (value: number) => {
    setSelectedAccount(value);
  }

  const handleDateChange = (date: any) => {
    setCompetencia(date);
  }
  
  const configRender = (value: string, record: any) => {
    const cor = record.origem === "C" ? "#282832" : record.origem === "D" ? "red" : "black";
    return value === '0.1.R' || value === '0.2.D' || value === '0.3.L' 
      ? '' 
      : record.tipoConta === "S" 
         ? <b><span style={{ color: cor }}>{<CurrencyFormat moeda={record.moeda} valor={value}/>}</span></b> 
         : <span style={{ color: cor }}>{<CurrencyFormat moeda={record.moeda} valor={value}/>}</span>;
  };

  const configClassName = (record: any) => 
    record.codigo === '0.1.R' || record.codigo === '0.2.D' || record.codigo === '0.3.L' ? "cor-destaque-backgroud" : "";
  
  return (
    <Content className="content"> 
      <h1>DFC - Demostrativo de Fluxo de Caixa</h1>
      <Space>
        <DatePicker picker="year" placeholder="Selecione o ano" value={competencia} defaultValue={dayjs(competencia)} onChange={handleDateChange}/>
        <Select placeholder="Selecione a conta corrente" style={{ width: 300 }} onChange={handleAccountChange} options={listaContaCorrente}/>
      </Space>
      <Table dataSource={listaSaldos} rowClassName={configClassName}>
        <Column          
          align="right"
          title="Código" 
          dataIndex="codigo" 
          key="codigo" 
          render={configRender}/>
        <Column 
          title="Descrição" 
          dataIndex="descricao" 
          key="descricao"
          render={configRender}/>
        <Column
          align="right"
          title="Saldo Total" 
          dataIndex="saldoTotal" 
          key="saldoTotal"
          render={configRender}/>
        <Column
          align="right"
          title="Janeiro" 
          dataIndex="janeiro" 
          key="janeiro"
          render={configRender}/>
        <Column 
          align="right"
          title="Fevereiro" 
          dataIndex="fevereiro" 
          key="fevereiro"
          render={configRender}/>
        <Column 
          align="right"
          title="Março" 
          dataIndex="marco" 
          key="marco"
          render={configRender}/>
        <Column
          align="right"
          title="Abril" 
          dataIndex="abril" 
          key="abril"
          render={configRender}/>
        <Column
          align="right"
          title="Maio" 
          dataIndex="maio" 
          key="maio"
          render={configRender}/>
        <Column 
          align="right"
          title="Junho" 
          dataIndex="junho" 
          key="junho"
          render={configRender}/>
        <Column 
          align="right"
          title="Julho" 
          dataIndex="julho" 
          key="julho"
          render={configRender}/>
        <Column 
          align="right"
          title="Agosto" 
          dataIndex="agosto" 
          key="agosto"
          render={configRender}/>
        <Column 
          align="right"
          title="Setembro" 
          dataIndex="setembro" 
          key="setembro"
          render={configRender}/>
        <Column 
          align="right"
          title="Outubro" 
          dataIndex="outubro" 
          key="outubro"
          render={configRender}/>
        <Column 
          align="right"
          title="Novembro" 
          dataIndex="novembro" 
          key="novembro"
          render={configRender}/>
        <Column 
          align="right" 
          title="Dezembro" 
          dataIndex="dezembro" 
          key="dezembro"
          render={configRender}/>
      </Table>    
    </Content>
  )
}

export default Dfc