import { Badge, Button, Col, DatePicker, Form, Popconfirm, Row, Space, Table, Tooltip } from "antd";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Saldo from '../../components/balance';
import { IBodyMovimento } from "../../shared/interfaces";
import { deleteMovimento, getMovimento, getMovimentos, postMovimento, putMovimento } from "../../shared/services/api";
import MovimentoModal from "./movimentoModal";
import MovimentReplyModal from "./movimentReplyModal";

interface Moviment {
  id: number;
  dataMovimento: Date;
  dataPagamento: Date;
  historico: string;
  valor: number;
  percentual: number;
  contaId: number;
  contaCodigo: string;
  contaDescricao: string;
}

const TableMovimento = (props: { contaCorrenteId: number }) => {
  const [movimentos, setMovimentos] = useState<Moviment[]>([]);
  const [competencia, setCompetencia] = useState(dayjs(new Date()).startOf('month'));
  const [modalVisible, setModalVisible] = useState(false);
  const [editingMoviment, setEditingMoviment] = useState<Moviment | null>(null);
  const [refreshKey, setRefreshKey] = useState(false);
  const [modalReplyVisible, setModalReplyVisible] = useState(false);
  const [saldoKey, setSaldoKey] = useState(0);

  useEffect(() => {
    fetchMoviments();
  }, [competencia, refreshKey, saldoKey]);

  const fetchMoviments = async () => {
    const response = await getMovimentos({
      contaCorrenteId: props.contaCorrenteId,
      competenciaInicial: competencia.startOf('month').toDate(),
      competenciaFinal: competencia.endOf('month').toDate()
    });
    setMovimentos(response.data.data);
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setCompetencia(date);
    }
  };

  const handleSaveLancamento = (movimentos: Moviment[]) => {
    movimentos.forEach((movimento) => saveMovimento(movimento));
    setModalReplyVisible(false);
    setRefreshKey(!refreshKey);
  };

  const saveMovimento = async (movimento: Moviment) => {
    const lancamento = movimentoToBodyMovimento(movimento);
    lancamento.percentual = 0;
    if (movimento.id && movimento.id !== 0) {
      await putMovimento(lancamento);
    } else {
      try {
        await postMovimento(lancamento);
      } catch (error) {
        console.error('Error creating account transaction : ', error);
      }
    }
    fetchMoviments();
    setSaldoKey(saldoKey + 1);
  };

  const movimentoToBodyMovimento = (movimento: Moviment): IBodyMovimento => {
    const { id, dataMovimento, dataPagamento, historico, valor, percentual, contaId } = movimento;
    const contaCorrenteId = props.contaCorrenteId;

    return {
      id,
      dataMovimento,
      dataPagamento,
      historico,
      contaId,
      contaCorrenteId,
      valor,
      percentual,
    };
  };

  const handleDeleteCell = async (id: number) => {
    await deleteMovimento(id);
    fetchMoviments();
    setRefreshKey(true);
  };

  const handleEditMoviment = async (movimentoId: number) => {
    const response = await getMovimento(movimentoId);
    setEditingMoviment(response.data.data);
    setModalVisible(true);
  };

  const configClassName = (record: Moviment) =>
    record.valor >= 0 ? "cor-positiva" : "cor-negativa";

  const configRenderValue = (value: number) => {
    return <span>{value.toFixed(2)}</span>;
  };

  const handleCellClick = async (record: Moviment) => {
    if (dayjs(record.dataPagamento).format("DD/MM/YYYY") === "01/01/1901") {
      const updatedMovimento = { ...record, dataPagamento: new Date() };
      await saveMovimento(updatedMovimento);
      fetchMoviments();
    }
  };

  const configRenderDate = (value: Date, record: Moviment) => {
    const isDefaultDate = dayjs(value).format("DD/MM/YYYY") === "01/01/1901";
    return (
      <Tooltip title="Clique para atualizar a data do pagamento">
        <span onClick={() => handleCellClick(record)} style={{ cursor: 'pointer' }}>
          {isDefaultDate ? "" : dayjs(value).format("DD/MM/YYYY")}
        </span>
      </Tooltip>
    );
  };

  const configRenderActions = (value: number): React.ReactNode => {
    return (
      <Space>
        <a onClick={() => handleEditMoviment(value)}>Editar</a>
        <Popconfirm title="Deletar Movimento?" onConfirm={() => handleDeleteCell(value)}>
          <a>Deletar</a>
        </Popconfirm>
      </Space>
    );
  };

  const configRenderStatus = (value: Date) => {
    const status = dayjs(value).format("DD/MM/YYYY") !== "01/01/1901" ? "success" : "error";
    const text = dayjs(value).format("DD/MM/YYYY") !== "01/01/1901" ? "Pago" : "Aberto";

    return (<Badge status={status} text={text} />);
  };

  return (
    <>
      <Form>
        <Row>
          <Col span={8}>
            <Space>
              <DatePicker picker="month" onChange={handleDateChange} value={competencia} defaultValue={dayjs(competencia)} format={"MM/YYYY"} />
              <Button onClick={() => setModalVisible(true)}>Novo</Button>
              <Button onClick={() => setModalReplyVisible(true)}>Replicar</Button>
            </Space>
          </Col>
          <Col>
            <Space>
              <Saldo contaCorrenteId={props.contaCorrenteId} competencia={competencia.toDate()} refreshKey={refreshKey} key={saldoKey}/>
            </Space>
          </Col>
        </Row>
        <Table dataSource={movimentos} rowClassName={configClassName}>
          <Column
            align="center"
            title="Status"
            dataIndex="dataPagamento"
            key="dataPagamento"
            render={configRenderStatus} />
          <Column
            align="center"
            title="Data Movimento"
            dataIndex="dataMovimento"
            key="dataMovimento"
            render={configRenderDate} />
          <Column
            align="center"
            title="Data Pagamento"
            dataIndex="dataPagamento"
            key="dataPagamento"
            render={configRenderDate} />
          <Column
            title="Historico"
            dataIndex="historico"
            key="historico" />
          <Column
            align="right"
            title="Conta"
            dataIndex="contaCodigo"
            key="contaCodigo" />
          <Column
            title="Descrição"
            dataIndex="contaDescricao"
            key="contaDescricao" />
          <Column
            align="right"
            title="Valor"
            dataIndex="valor"
            key="valor"
            render={configRenderValue} />
          <Column
            align="right"
            title="%"
            dataIndex="percentual"
            key="percentual"
            render={configRenderValue} />
          <Column
            title="Ações"
            dataIndex="id"
            key="id"
            align="center"
            render={configRenderActions}
          />
        </Table>
      </Form>
      <MovimentoModal
        contaCorrenteId={props.contaCorrenteId}
        editingMovimento={editingMoviment}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={(movimento: Moviment) => {
          handleSaveLancamento([movimento]);
        }} />
      <MovimentReplyModal
        contaCorrenteId={props.contaCorrenteId}
        competencia={competencia.toDate()}
        open={modalReplyVisible}
        onCancel={() => setModalReplyVisible(false)}
        onOk={handleSaveLancamento} />
    </>
  );
}

export default TableMovimento;
