import { Button, Collapse } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { IBodyContaPlanoConta } from '../../shared/interfaces';
import { deleteConta, deletePlanoConta, getContasPlano, getPlanoContas, postConta, postPlanoConta, putConta, putPlanoConta } from '../../shared/services/api';
import ControlPlanAccount from './controlPlanAccount';
import { Account } from './interfaces/Account';
import { PlanAccount } from './interfaces/PlanAccount';
import TableAccount from './tableAccount';

const { Panel } = Collapse;

const PlanoConta: React.FC = () => {
  const [planoContas, setPlanoContas] = useState<PlanAccount[]>([]);
  const [dataAccount, setDataAccount] = useState<Account[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    carregarPlanoContas();
  }, []);

  const carregarPlanoContas = async () => {  
    const plano = await getPlanoContas();
    setPlanoContas(plano.data.data);
  };

  const carregarContasPlano = async (planoId: number) => {
    const contas = await getContasPlano(planoId);
    setDataAccount(contas.data.data);
  };

  const handleCollapseChange = (planoId: number | any) => {
    if (typeof planoId === 'object') {
      carregarContasPlano(planoId);
    }
  };

  const handleDeleteAccount = async (contaId: number, planoContaId: number) => {
    await deleteConta(contaId);
    carregarContasPlano(planoContaId);
  };

  const handleAccount = async (conta: Account) => {
    saveAccount(conta);
  };

  const saveAccount = async (account: Account) => {
    const conta = contaToBodyConta(account);
    if (account.id == undefined) {
      await postConta(conta);
    }
    else {
      await putConta(conta);
    }
    
    carregarContasPlano(conta.PlanoContaId);
  }

  const contaToBodyConta = (conta: Account): IBodyContaPlanoConta => {
    const { id, codigo, descricao, tipoConta, origem, planoContaId } = conta;

    return {
      Id: id,
      Codigo: codigo, 
      Descricao: descricao, 
      TipoConta: tipoConta, 
      Origem: origem, 
      PlanoContaId: planoContaId
    };
  }

  const handleModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    carregarPlanoContas();
  };

  const handleUpdate = async (updateRecord: PlanAccount) => {
    await putPlanoConta(updateRecord);
  };

  const handleInsert = async (insertRecord: PlanAccount) => {
    try {
      await postPlanoConta(insertRecord);
      carregarPlanoContas();
    } catch (error) {
      carregarPlanoContas();
      console.error('Error creating plan account: ', error)
    }
  };

  const handleDelete = async (planoId: number) => {
    await deletePlanoConta(planoId)
  };

  return (    
    <Content className="content"> 
      <h1>Plano de Contas</h1>     
      <Button className="btn-default" onClick={handleModal} style={{ marginBottom: '10px' }}>Plano de Contas</Button>
      <Collapse accordion onChange={handleCollapseChange}>
        {planoContas.map((plano) => (
          <Panel header={plano.nome + ' - ' + plano.classificacao} key={plano.id}>
            <TableAccount planoContaId={plano.id} data={dataAccount} onDelete={handleDeleteAccount} onCreate={handleAccount}/>
          </Panel>
        ))}
      </Collapse>
      <ControlPlanAccount data={planoContas} open={isModalVisible} onCancel={handleCancel} onUpdate={handleUpdate} onInsert={handleInsert} onDelete={handleDelete}/>
    </Content>
  );
};

export default PlanoConta;
