/* eslint-disable react/prop-types */
import { Button, Form, Popconfirm, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { IBodyContaCorrente } from "../../shared/interfaces";
import { deleteContaCorrente, getContaCorrente, getContasCorrentes, postContaCorrente, putContaCorrente } from "../../shared/services/api";
import ContaCorrenteModal from "./contaCorrenteModal";
import { Account } from "./interfaces/Account";

const TableContaCorrente = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contasCorrente, setContasCorrente] = useState<Account[]>([]);
  const [editingAccount, setEditingAccount] = useState<Account | null>(null);

  const columns = [
    {
      title: 'Número',
      dataIndex: 'numero',
      key: 'numero',
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Moeda',
      dataIndex: 'moeda',
      key: 'moeda',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        const color = status === "A" ? "green" : "volcano";
        const tag = status === "A" ? "Ativo" : "Inativo";

        return (<Tag color={color} key={tag}>{tag}</Tag>)
      }
    },
    {
      title: 'Tela Principal',
      dataIndex: 'statusTelaPrincipal',
      key: 'statusTelaPrincipal',      
      render: (statusTelaPrincipal: boolean) => {
        const color = statusTelaPrincipal ? "green" : "volcano";
        const tag = statusTelaPrincipal ? "Habilitado" : "Desabilitado";

        return (<Tag color={color} key={tag}>{tag}</Tag>)
      },
    },
    {
      title: 'Banco',
      dataIndex: 'bancoNome',
      key: 'bancoNome',
    },
    {
      title: 'Agencia',
      dataIndex: 'agenciaNome',
      key: 'agenciaNome',
    },
    {
      title: 'Plano Conta',
      dataIndex: 'planoContaNome',
      key: 'planoContaNome',
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_text: string, record: Account) => ((
        <Space>
          <a onClick={() => handleEditAccount(record.id)}>Editar</a>
          <Popconfirm title="Deletar Conta Corrente?" onConfirm={() => handleDeleteCell(record.id)}>
            <a>Deletar</a>
          </Popconfirm>
        </Space>
      )),
    },
  ];  
  
  useEffect(() => {    
    fetchCheckingAccounts();
  }, []);

  const fetchCheckingAccounts = async () => {
    const response = await getContasCorrentes(false);
    setContasCorrente(response.data.data);
  }    

  const handleDeleteCell = async (id: number) => {
    await deleteContaCorrente(id);
    fetchCheckingAccounts();
  };
  
  const handleSaveContaCorrente = (contaCorrente: Account) => {
    saveCheckingAccount(contaCorrente);
    setIsModalVisible(false);
  };

  const saveCheckingAccount = async (contaCorrente: Account) => {
    const conta = contaCorrenteToBodyContaCorrente(contaCorrente);
    if (contaCorrente.id) {      
      await putContaCorrente(conta);
    } else {
      try {
        await postContaCorrente(conta);
      } catch (error) {
        console.error('Error creating checking account: ', error)
      }
    }
    fetchCheckingAccounts();
  }

  const contaCorrenteToBodyContaCorrente = (contaCorrente: Account): IBodyContaCorrente => {
    const { id, numero, descricao, moeda, status, statusTelaPrincipal, agenciaId, bancoId, planoContaId } = contaCorrente;    
  
    return {
      id,
      numero,
      descricao,
      moeda,
      status,
      statusTelaPrincipal,
      agenciaId,
      bancoId,
      planoContaId
    };
  };

  const handleEditAccount = async (contaCorrenteId: number) => {
    const response = await getContaCorrente(contaCorrenteId);
    setEditingAccount(response.data.data);
    setIsModalVisible(true);
  }   

  return (
    <div>
      <Form>
        <Button onClick={() => setIsModalVisible(true)}>Adicionar Conta Corrente</Button>
        <Table dataSource={contasCorrente} columns={columns} />
      </Form>
      <ContaCorrenteModal
        editingAccount={editingAccount}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSaveContaCorrente}/>
    </div>
  );
}

export default TableContaCorrente