
import { useEffect, useState } from "react";
import decodeToken from "../tools/decodeToken";

const Saudacao = () => {
  const [hello, setHello] = useState("");
  const [time, setTime] = useState(new Date().getHours());

  useEffect(() => {
    (async () => {
      setTime(new Date().getHours());
      const nameUser = decodeToken()

      if (time >= 0){
      setHello("Bom dia, " + nameUser);
      }
      if (time >= 12){
        setHello("Bom tarde, " + nameUser);
      }
      if (time >= 18){
        setHello("Boa noite, " + nameUser);
      }

    })();
  }, [time]);
  
  return (
    <p className="texto" style={{ margin: '30px 0' }}>{hello}</p>
  )
}

export default Saudacao