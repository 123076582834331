/* eslint-disable react/prop-types */

import { Button, Form, Input, Modal, Popconfirm, Space, Switch, Table, Tag } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { Account } from "./interfaces/Account";

interface AccountTableProps {
  planoContaId: number;
  data: Account[];
  onDelete: (id: number, planoContaId: number) => void;
  onCreate: (newData: Account) => void;
}

const TableAccount: React.FC<AccountTableProps> = ({ planoContaId, data, onDelete, onCreate }) => { 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [tipoConta, setTipoConta] = useState<boolean>(true);
  const [origem, setOrigem] = useState<boolean>(true);
  const [editingAccount, setEditingAccount] = useState<boolean>(false);

  const columns:ColumnsType<Account> = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      align: 'right',
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Tipo',
      dataIndex: 'tipoConta',
      key: 'tipoConta',
      render: (tipoConta: string) => {
        const tag = tipoConta === "A" ? "Analitico" : "Sintetico";

        return (<Tag color={"default"} key={tag}>{tag}</Tag>)
      }
    },
    {
      title: 'Origem',
      dataIndex: 'origem',
      key: 'origem',
      render: (origem: string) => {
        const color = origem === "C" ? "green" : "volcano";
        const tag = origem === "C" ? "Credito" : "Debito";

        return (<Tag color={color} key={tag}>{tag}</Tag>)
      }
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_text: string, record: Account) => ((
        <Space>
          <a onClick={() => handleEdit(record)}>Editar</a>
          <Popconfirm title="Deletar Conta?" onConfirm={() => onDelete(record.id, record.planoContaId)}>
            <a>Deletar</a>
          </Popconfirm>
        </Space>
      )),
    },
  ];

  const handleEdit = (record: Account) => {
    form.setFieldsValue(record);
    setTipoConta(record.tipoConta === "A");
    setOrigem(record.origem === "C");

    const isEditing = !!form.getFieldValue("id"); 
    setEditingAccount(isEditing);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCreate = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleTipoContaChange = (checked: boolean) => {
    setTipoConta(checked);
    form.setFieldsValue({
      tipoConta: checked ? "A": "S",
    });
  };

  const handleOrigemChange = (checked: boolean) => {
    setOrigem(checked);
    form.setFieldsValue({
      origem: checked ? "C": "D",
    });
  };

  const getRowClassName = (record: Account) => {
    let className = "";

    className = record.origem === "D" ? "cor-negativa " : "cor-positiva ";
    className += record.tipoConta === "S" ? "font-destaque " : "";

    return className;
  }

  const handleSave = () => {
    
    form.setFieldsValue({
      tipoConta: tipoConta ? "A" : "S",
      origem: origem ? "C" : "D",
      planoContaId: planoContaId
    });
           
    form.validateFields()
      .then((values) => {
        onCreate(values);        
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.log('Erro ao salvar conta:', errorInfo);
      });

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form.resetFields();
    handleModalCancel();
  }

  return (
    <Content className="content">   
      <Button onClick={handleCreate} style={{ marginBottom: '10px' }}>Adicionar Conta</Button>
      <Table columns={columns} dataSource={data} rowClassName={getRowClassName}></Table>
      <Modal 
        title={editingAccount ? "Editar Conta" : "Nova Conta"}
        open={isModalVisible}        
        onCancel={handleModalCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button className="btn-primary" key="save" type="primary" onClick={handleSave}>
            Salvar
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={{
          tipoConta: "A",
          origem: "C"
        }}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="planoContaId" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="Código" name="codigo" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="descricao" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Space>
            <Form.Item label="Tipo Conta" name="tipoConta" rules={[{ required: true }]}>
              <Switch checked={tipoConta} checkedChildren="Analítico" unCheckedChildren="Sintético" onChange={handleTipoContaChange}/>
            </Form.Item>
            <Form.Item label="Origem" name="origem" rules={[{ required: true }]}>
              <Switch checked={origem} checkedChildren="Crédito" unCheckedChildren="Débito" onChange={handleOrigemChange}/>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </Content>
    
  );
}

export default TableAccount