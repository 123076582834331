import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface BalanceItem {
  ano: number;
  mes: string;
  moeda: string;
  total_Positivo: number;
  total_Negativo: number;
}

interface charBarProps {
  balance: BalanceItem[]
}

const ChartBar: React.FC<charBarProps> = ({balance}) => {
  const labels = balance.map(item => `${item.mes}/${item.ano}`);  
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Receita',
        data: balance.map(item => item.total_Positivo),
        backgroundColor: '#236ecf',
      },
      {
        label: 'Despesa',
        data: balance.map(item => item.total_Negativo * -1),
        backgroundColor: '#ff6347',
      },
    ],
  };

  return ( 
    <Bar data={data} />    
  )
};

ChartBar.propTypes = {
  balance: PropTypes.any.isRequired
}

export default ChartBar;
