/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Space } from "antd";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { login, postEmail, postRegister } from "../../shared/services/api";
import TermsOfUseModal from '../Welcome/termsOfUseModal';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [isModalForgotVisible, setIsModalForgotVisible] = useState(false);
  const [isModalRegisterVisible, setIsModalRegisterVisible] = useState(false);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModaForgotlOk = async () => {
    try {    
      await form.validateFields();
      await postEmail({ email: form.getFieldValue('email')})      
      setIsModalForgotVisible(false);
      navigate("/");
    } catch (error) {
      console.clear()
    }
  };

  const handleModalForgotCancel = async () => {
    form.resetFields();
    setIsModalForgotVisible(false);
    navigate("/");
  }

  const handleCreateForgot = () => {
    form.resetFields();
    setIsModalForgotVisible(true);
  };

  const handleModalRegisterOk = async () => {
    try {    
      await form.validateFields();
      await postRegister({ nome: form.getFieldValue('nome'), email: form.getFieldValue('email'), senha: form.getFieldValue('senha')})      
      setIsModalRegisterVisible(false);
      navigate("/");
    } catch (error) {
      console.error("")
    }
  };

  const handleModalRegisterCancel = async () => {
    form.resetFields();
    setIsModalRegisterVisible(false);
    navigate("/");
  }

  const handleCreateRegister = () => {
    form.resetFields();
    setIsModalRegisterVisible(true);
  };

  const onFinish = async (values: any) => {  
    
    try {
      const response = await login({ email: values.email, senha: values.senha })
      if (response) {
        const tokenResponse = response.data
        localStorage.setItem('token', tokenResponse);
        navigate("/"); 
      }      
      
    } catch (error: any) {
        if (error.status === 401) {
          localStorage.removeItem('token');
        }
    }
  };  

  const showTermsOfUseModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row >
        <Col 
          flex={1} 
          >
          <div className="App-header">
            <Form
              name="basic"        
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off">

              <Form.Item
                className="label-Login"
                
                name="email"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail"/>
              </Form.Item>

              <Form.Item
                className="label-Login"
                
                name="senha"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password"/>
              </Form.Item>
              <Form.Item>              
                <a className="login-form-forgot" onClick={handleCreateForgot}>
                  Forgot password
                </a>
              </Form.Item>

              <Form.Item>
                <Space>
                  <Button htmlType="submit">
                    Log in
                  </Button>
                  <p className="login-form-forgot">or</p>
                  <a className="login-form-forgot" onClick={handleCreateRegister}>register now!</a>
                </Space>
              </Form.Item>
            </Form>      
          </div>
        </Col>
        <Col flex={4}
          style={{ textAlign: 'center', background: `url('https://images.hive.blog/p/PB8ro82ZpZP2DJYqWdv8SjVQiQasiEcGctw3Jrh4b5JpqYUgTm3bMaAJV5uKSCa6q7VWjmFKRytZ9NgKpekoaJCprh1DLEZG1uPPd6EVvxWZADug?format=match&mode=fit') center/cover no-repeat`, opacity: 0.5 }}>
          <Row justify="center" align="middle" style={{ height: '100vh' }}>
            <Col>
              <h1>FinPes</h1>
              <h3 aria-level={2}>O seu aplicativo de controle financeiro.</h3>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal title="Forgot password" open={isModalForgotVisible} onOk={handleModaForgotlOk} onCancel={handleModalForgotCancel}>
        <Form form={form} layout="vertical">          
          <Form.Item className="label-Login" name="email" rules={[{ required: true, message: 'Please input your e-mail!' }]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />}placeholder="E-mail"/>
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="Sign up" open={isModalRegisterVisible} onOk={handleModalRegisterOk} onCancel={handleModalRegisterCancel}>
        <Form form={form} layout="vertical">          
          <Form.Item className="label-Login" name="nome" rules={[{ required: true, message: 'Please input your nome!' }]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Name"/>
          </Form.Item>          
          <Form.Item className="label-Login" name="email" rules={[{ required: true, message: 'Please input your e-mail!' }]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail"/>
          </Form.Item>
          <Form.Item className="label-Login" name="senha" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password"/>
          </Form.Item>
          <Button type="link" onClick={showTermsOfUseModal}>
                  Termos de Uso
                </Button>
        </Form>
      </Modal>
      <TermsOfUseModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />      
    </>    
  )

}

export default Login;