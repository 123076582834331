import { Button, Form, Modal, Table, Checkbox } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { getMovimentos } from "../../shared/services/api";
import dayjs from "dayjs";

interface Movimento {
  id: number;
  dataMovimento: Date;
  dataPagamento: Date;
  historico: string;
  valor: number;
  percentual: number;
  contaId: number;
  contaCodigo: string;
  contaDescricao: string;
}

interface MovimentReplyModalProps {
  contaCorrenteId: number;
  competencia: Date;
  open: boolean;
  onCancel: () => void;
  onOk: (novoLancamento: Movimento[]) => void;
}

const MovimentReplyModal = ({ contaCorrenteId, competencia, open, onCancel, onOk }: MovimentReplyModalProps) => {
  const [form] = Form.useForm();
  const [movimentos, setMovimentos] = useState<Movimento[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchMoviments();
  }, [open, currentPage, pageSize]);

  const fetchMoviments = async () => {    
    const previousMonth = dayjs(competencia).subtract(1, 'month');
    const response = await getMovimentos({
      contaCorrenteId: contaCorrenteId,
      competenciaInicial: previousMonth.startOf('month').toDate(),
      competenciaFinal: previousMonth.endOf('month').toDate()
    });
    setMovimentos(response.data.data);
  };

  const handleCancel = () => {
    setSelectedRowKeys([]);
    onCancel();
  };

  const handleSave = () => {
    const selectedMovimentos = movimentos
      .filter((movimento) => selectedRowKeys.includes(movimento.id))
      .map((movimento) => ({
        ...movimento,
        id: 0,
        dataMovimento: dayjs(movimento.dataMovimento).add(1, 'month').toDate(),
        dataPagamento: dayjs(undefined, 'YYYY-MM-DD').toDate()
      }));
    onOk(selectedMovimentos);
    setSelectedRowKeys([]);
  };

  const handleSelect = (id: number) => {
    setSelectedRowKeys((prevSelectedRowKeys) =>
      prevSelectedRowKeys.includes(id)
        ? prevSelectedRowKeys.filter((key) => key !== id)
        : [...prevSelectedRowKeys, id]
    );
  };

  const configClassName = (record: Movimento) =>
    record.valor >= 0 ? "cor-positiva" : "cor-negativa";

  const columns: ColumnsType<Movimento> = [
    {
      title: 'Selecionar',
      dataIndex: 'id',
      align: 'center' as const,
      width: 'auto',
      render: (text: string, record: Movimento) => (
        <Checkbox checked={selectedRowKeys.includes(record.id)} onChange={() => handleSelect(record.id)} />
      ),
    },
    {
      title: 'Data Movimento',
      dataIndex: 'dataMovimento',
      render: (text: string) => dayjs(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Histórico',
      dataIndex: 'historico',
    },
    {
      title: 'Conta Código',
      dataIndex: 'contaCodigo',
    },
    {
      title: 'Conta Descrição',
      dataIndex: 'contaDescricao',
      className: 'hideOnMobile', 
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      align: 'right' as const,
      render: (value: number) => value.toFixed(2),
    }
  ];

  return (
    <Modal
      centered
      closeIcon
      open={open}
      title={'Replicar Lançamentos'}
      width={'auto'}
      footer={[
        <Button key='cancel' onClick={handleCancel}>Cancelar</Button>,
        <Button className='btn-primary' key='save' type='primary' onClick={handleSave}>Salvar</Button>
      ]}
    >
      <Form
        form={form}
        labelAlign="left"
        labelWrap
        colon={false}
        wrapperCol={{ flex: 1 }}
        style={{ width: '100%' }}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={movimentos}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            }
          }}
          style={{ width: '100%' }}
          rowClassName={configClassName}
        />
      </Form>
    </Modal>
  );
};

export default MovimentReplyModal;
