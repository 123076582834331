import { Card, Col, Layout, Row } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getSaldoReceitaDespesa, getSintetico } from "../../shared/services/api";
import ChartBar from "./chartBar";
import ChartLineNegative from "./chartLineNegative";
import ChartLinePositive from "./chartLinePositive";
import ChartPie from "./chartPie";
import TablePie from "./tablePie";

const { Content } = Layout;

const Saldo = (props: {contaCorrenteId: number}) => {  
  const [balance, setBalance] = useState([]);
  const [summary, setSumary] = useState([]);
  const [competencia] = useState(dayjs(new Date()).startOf('month'))

  useEffect(() => {
    fetchBalance(props.contaCorrenteId);
  }, [props.contaCorrenteId]);

  const fetchBalance = async (contaCorrenteId: number) => {
    const response = await getSaldoReceitaDespesa(contaCorrenteId, competencia.year());
    
    setBalance(response.data.data);
  }

  useEffect(() => {
    fetchBalanceSummary(props.contaCorrenteId)
  }, [props.contaCorrenteId]);

  const fetchBalanceSummary = async (contaCorrenteId: number) => {
    const response = await getSintetico(contaCorrenteId, competencia.toDate());
    
    setSumary(response.data.data);
  }
  
  return (
    <Layout>
      <Content style={{ overflow: 'auto', height: 'calc(80vh - 80px)', padding: '5px' }}>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={12} md={12} lg={6}>
            <Card hoverable title="Receitas e despesas no ano corrente" size="small" style={{ height: '100%' }}>
              <ChartBar balance={balance}/>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6}>
            <Card hoverable title="Receitas e despesas por mês" size="small" style={{ height: '100%' }}>
              <ChartLinePositive balance={balance}/>
              <ChartLineNegative balance={balance}/>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6}>
            <Card hoverable title="Receitas e despesas sintéticas mês corrente" size="small" style={{ height: '100%' }}>
              <ChartPie balance={summary}/> 
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6}>
            <Card hoverable title="Receitas e despesas sintéticas mês corrente" size="small" style={{ height: '100%' }}>
              <TablePie balance={summary}/>
            </Card>
          </Col>              
        </Row>
      </Content>
    </Layout>
  )
}

export default Saldo;
