/* eslint-disable react/react-in-jsx-scope */
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRoutes from './AppRoutes';

function App() {
  return (
      <BrowserRouter>
        <AppRoutes/> 
      </BrowserRouter>
  );
}

export default App;