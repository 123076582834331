import { Button, DatePicker, Form, Input, Modal } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { IBodyPassword, IBodyPessoa } from '../../shared/interfaces';
import { getPessoa, putPassword, putPessoa } from '../../shared/services/api';

const Perfil: React.FC = () => {
  const [formPessoa] = Form.useForm();
  const [formSenha] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPessoa();
        response.data.data.dataNascimento = dayjs(response.data.data.dataNascimento).format("DD/MM/YYYY") !== "01/01/1901" ? dayjs(response.data.data.dataNascimento) : null;
        formPessoa.setFieldsValue(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao obter os dados:", error);
      }
    };

    fetchData();
  }, [formPessoa]);

  const onFinish = async (values: IBodyPessoa) => {
    await putPessoa(values);
    setIsModalVisible(false);
  };

  const handlePasswordSubmit = async (values: IBodyPassword & { confirmarNovaSenha?: string }) => {
    const { confirmarNovaSenha, ...passwordData } = values;
    await putPassword(passwordData as IBodyPassword);
    setIsPasswordModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsPasswordModalVisible(false);
    formSenha.resetFields();
  };

  const showPasswordModal = () => {
    setIsPasswordModalVisible(true);
  };

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <Content className="content">
      <Form
        form={formPessoa}
        onFinish={onFinish}
        labelCol={{ flex: '150px' }}
        labelAlign="left"
        labelWrap
        colon={false}
        wrapperCol={{ flex: 1 }}
        style={{ maxWidth: 600 }}>
        <h1>Perfil</h1>
        <Form.Item label="Nome" name="nome" rules={[{ required: true, message: "Nome não pode ser vazio." }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="CPF" name="cpf" rules={[{ required: false }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="E-mail" name="email" rules={[{ required: true, message: "E-mail não pode ser vazio." }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="Telefone" name="telefone" rules={[{ required: false }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="Data Nascimento" name="dataNascimento" rules={[{ required: false }]}>
          <DatePicker format="DD/MM/YYYY" disabled />
        </Form.Item>
        <Form.Item>
          <Button className="btn-primary" type="primary" onClick={showModal}>Editar no Modal</Button>
          <Button className="btn-primary" type="primary" style={{ marginLeft: 8 }} onClick={showPasswordModal}>Alterar Senha</Button>
        </Form.Item>
      </Form>

      <Modal
        title="Editar Perfil"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button className="btn-primary" key="submit" type="primary" onClick={formPessoa.submit}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={formPessoa}
          onFinish={onFinish}
          labelCol={{ flex: '150px' }}
          labelAlign="left"
          labelWrap
          colon={false}
          wrapperCol={{ flex: 1 }}
          style={{ maxWidth: 600 }}>
          <Form.Item label="Nome" name="nome" rules={[{ required: true, message: "Nome não pode ser vazio." }]}>
            <Input />
          </Form.Item>
          <Form.Item label="CPF" name="cpf" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Telefone" name="telefone" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Data Nascimento" name="dataNascimento" rules={[{ required: false }]}>
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Alterar Senha"
        visible={isPasswordModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button className="btn-primary" key="submit" type="primary" onClick={() => formSenha.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={formSenha}
          onFinish={handlePasswordSubmit}
          labelCol={{ flex: '150px' }}
          labelAlign="left"
          labelWrap
          colon={false}
          wrapperCol={{ flex: 1 }}
          style={{ maxWidth: 600 }}>
          <Form.Item
            label="Senha Antiga"
            name="old"
            rules={[{ required: true, message: "Por favor, insira a senha antiga." }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Nova Senha"
            name="new"
            rules={[
              { required: true, message: "Por favor, insira a nova senha." },
              { min: 6, message: "A senha deve ter pelo menos 6 caracteres." },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirmar Nova Senha"
            name="confirmarNovaSenha"
            dependencies={['new']}
            rules={[
              { required: true, message: "Por favor, confirme a nova senha." },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('As senhas não coincidem.'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
};

export default Perfil;
