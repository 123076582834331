const decodeToken = () => {  
  const token = localStorage.getItem('token');
  const tokenTratado = token !== null ? token : "";

  const tokenPart = tokenTratado.split('.')[1]
  const tokenDecode = atob(tokenPart)
  const tokenx = JSON.parse(tokenDecode)
  const nome = tokenx.nome;

  return nome;
  
}

export default decodeToken