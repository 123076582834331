import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { deleteCartaoCredito, getAllCartaoCredito, postCartaoCredito, putCartaoCredito } from "../../shared/services/api";
import { CreditCard } from "./interfaces/CreditCard";
import TableCartaoCredito from "./tableCartaoCredito";

const CartaoCredito = () => {
  const [data, setData] = useState<CreditCard[]>([]);

  useEffect(() => {
    fetchCartaoCreditos();
  }, []);

  const fetchCartaoCreditos = async () => {
    const response = await getAllCartaoCredito();
    setData(response.data.data);
  }

  const handleDelete = async (CartaoCreditoId: number) => {   
    await deleteCartaoCredito(CartaoCreditoId);
    fetchCartaoCreditos();
  };

  const handleUpdate = async (updatedData: CreditCard) => {    
    await putCartaoCredito(updatedData);
    fetchCartaoCreditos();
  };

  const handleCreate = async (newData: CreditCard) => {
    try {
      await postCartaoCredito(newData);
      fetchCartaoCreditos();
    } catch (error) {
      console.error("Error creating credit card: ", error)
    }
  };

  return (
    <Content className="content"> 
      <h1>Cartão de Crédito</h1>
      <TableCartaoCredito data={data} onDelete={handleDelete} onUpdate={handleUpdate} onCreate={handleCreate} />      
    </Content> 
  )
}

export default CartaoCredito