/* eslint-disable react/prop-types */
import { Button, Form, Input, Modal, Popconfirm, Space, Table } from "antd";
import { useState } from "react";
import { Currency } from "./interfaces/Currency";

interface MoedaTableProps {
  data: Currency[];
  onDelete: (id: number) => void;
  onUpdate: (updatedData: Currency) => void;
  onCreate: (newData: Currency) => void;
}

const TableMoeda: React.FC<MoedaTableProps> = ({ data, onDelete, onUpdate, onCreate }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_text: string, record: Currency) => ((
        <Space>
          <a onClick={() => handleEdit(record)}>Editar</a>
          <Popconfirm title="Deletar Moeda?" onConfirm={() => onDelete(record.id)}>
            <a>Deletar</a>
          </Popconfirm>
        </Space>
      )),
    },
  ];

  const handleEdit = (record: Currency) => {
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    const isEditing = !!form.getFieldValue("id");
    const fieldsValue = await form.validateFields();

    const record: Currency = {
      ...fieldsValue,
      id: isEditing ? form.getFieldValue('id') : undefined,
    };

    if (isEditing) {
      onUpdate(record);
    } else {
      onCreate(record);
    }
  
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCreate = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  return (
    <div>
      <Button onClick={handleCreate}>Adicionar Moeda</Button>
      <Table dataSource={data} columns={columns} />

      <Modal title="Editar Moeda" open={isModalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
        <Form form={form} layout="vertical">
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="Código" name="codigo" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="nome" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default TableMoeda