import { Button, Input, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { PlanAccount } from "./interfaces/PlanAccount";

interface ModalControlPlanAccount {
  data: PlanAccount[];
  open: boolean;
  onCancel: () => void;
  onUpdate: (updatedData: PlanAccount) => void;
  onInsert: (newData: PlanAccount) => void;
  onDelete: (id: number) => void;
}

const ControlPlanAccount: React.FC<ModalControlPlanAccount> = ({
  data: initialData,
  open,
  onCancel,
  onUpdate,
  onInsert,
  onDelete,
}: ModalControlPlanAccount) => {
  const [editingKey, setEditingKey] = useState<number | string | undefined>(
    undefined
  );
  const [, setEditRowId] = useState<number | string | undefined>(
    undefined
  );
  const [tableData, setTableData] = useState<PlanAccount[]>([]);

  useEffect(() => {
    setTableData(initialData);
  }, [initialData]);

  const isEditing = (record: PlanAccount) => record.id === editingKey;

  const edit = (record: PlanAccount) => {
    setEditingKey(record.id);
    setEditRowId(record.id);
  };

  const cancel = () => {
    setEditingKey(undefined);
    setEditRowId(undefined);
  };

  const handleInsert = () => {
    const newRecord: PlanAccount = { id: 0, nome: '', classificacao: '', pessoaId: 0 };
    setEditingKey(newRecord.id);
    setEditRowId(newRecord.id);
    const newData = [...tableData, newRecord];
    setTableData(newData);
  };


  const save = async (record: PlanAccount) => {
    try {
      if (record.id === 0) {
        console.log("novo: ", record)
        onInsert(record);
      } else {
        console.log("Edit: ", record)
        onUpdate(record);
      }
      setEditingKey(undefined);
      setEditRowId(undefined);
    } catch (err) {
      console.error("Erro ao editar plano de conta:", err);
    }
  };

  const columns: ColumnsType<PlanAccount> = [
    {
      title: "Classificação",
      dataIndex: "classificacao",
      key: "classificacao",
      render: (_text: string, record: PlanAccount) =>
        isEditing(record) ? (
          <Input 
            defaultValue={record.classificacao} 
            onChange={(e) => {
              const value = e.target.value;
              record.classificacao = value;
            }}
          />
        ) : (
          record.classificacao
        ),
    },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      render: (_text: string, record: PlanAccount) =>
        isEditing(record) ? (
          <Input 
            defaultValue={record.nome} 
            onChange={(e) => {
              const value = e.target.value;
              record.nome = value;
            }}
          />
        ) : (
          record.nome
        ),
    },
    {
      title: "Ações",
      key: "actions",
      render: (_text: string, record: PlanAccount) => (
        <Space>
          {isEditing(record) ? (
            <Space>
              <Button onClick={() => save(record)}>Salvar</Button>
              <Button onClick={cancel}>Cancelar</Button>
            </Space>
          ) : (
            <>
              <Button onClick={() => edit(record)}>Editar</Button>
              <Button onClick={() => handleDelete(record)}>Deletar</Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  const handleDelete = (record: PlanAccount) => {
    Modal.confirm({
      title: 'Tem certeza que deseja deletar este plano de conta?',
      content: 'Esta ação é irreversível!',
      onOk: () => {
        onDelete(record.id);
        const newData = tableData.filter(item => item.id !== record.id);
        setTableData(newData);
      }
    });
  };

  return (
    <Modal
      title="Plano de Contas"
      visible={open}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}
      width={700}
      closeIcon={<span className="custom-modal-close">✖️</span>}
    >
      <Button onClick={handleInsert}>Novo Plano de Contas</Button>
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        size="small"
      />
    </Modal>
  );
};

export default ControlPlanAccount;
