import { Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import { SetStateAction, useEffect, useState } from "react";
import { getContasCorrentes } from "../../shared/services/api";
import TableMovimento from "./tableMovimento";

interface contaCorrente {
  id: number,
  numero: string,
  descricao: string
}

const ContaCorrenteTabs = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [listaContaCorrente, setListaContaCorrente] = useState([]);

  useEffect(() => {
    fetchAccounts();
  }, [activeTab]);

  const fetchAccounts = async () => {
    try {
      const response = await getContasCorrentes(true);

      const contas: any = []
      response.data.data.map((conta: contaCorrente) => {
        contas.push({
          value: conta.id,
          label: `${conta.descricao} (${conta.numero})`,
        })
      })    
      setListaContaCorrente(contas);
      setActiveTab(contas[0].id)
    }
    catch (error) {
      console.clear()
    }
  }

  const handleTabChange = (key: SetStateAction<string>) => {
    setActiveTab(key);
  };

  const items = listaContaCorrente.map((conta: any) => ({
    key: conta.value,
    label: conta.label,
    children: <><TableMovimento contaCorrenteId={conta.value}/></>
  }));

  return (
    <Tabs defaultActiveKey={activeTab} items={items} onChange={handleTabChange}/>
  )
};

const Movimento: React.FC = () => {
  
  return (
    <Content className="content"> 
      <h1>Lançamentos por conta corrente</h1>
      <ContaCorrenteTabs />
    </Content>
  )
}

export default Movimento;