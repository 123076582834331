/* eslint-disable @typescript-eslint/no-explicit-any */
const CurrencyFormat = (props:any) => {
    return (
        props.moeda === "BRL" ? 
            props.valor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2, currencyDisplay: 'symbol'}) :
        props.moeda === "USD" ?
            props.valor.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, currencyDisplay: 'symbol'}) :
        props.moeda === "EUR" ?
            props.valor.toLocaleString(undefined, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2, currencyDisplay: 'symbol'}) :
            props.valor.toLocaleString(undefined, {minimumFractionDigits: 2})
    )
}

export default CurrencyFormat