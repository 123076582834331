import { Button, Steps, Tabs, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { SetStateAction, useEffect, useState } from "react";
import { getContasCorrentes } from "../../shared/services/api";
import Agencia from "../Agencia";
import Banco from "../Banco";
import ContaCorrente from "../ContaCorrente";
import Moeda from "../Moeda";
import PlanoConta from "../PlanoConta";
import Saldo from "./saldo";

interface contaCorrente {
  id: number,
  numero: string,
  descricao: string
}

interface conta {
  value: number,
  label: string
}

const steps = [  
  {
    title: 'Moeda',
    content: <div style={{ height: 'auto', overflowY: 'auto' }}><Moeda/></div>
  },
  {
    title: 'Banco',
    content: <div style={{ height: 'auto', overflowY: 'auto' }}><Banco/></div>
  },
  {
    title: 'Agencia',
    content: <div style={{ height: 'auto', overflowY: 'auto' }}><Agencia/></div>
  },
  {
    title: 'Plano de Conta',
    content: <div style={{ height: 'auto', overflowY: 'auto' }}><PlanoConta/></div>
  },
  {
    title: 'Conta Corrente',    
    content: <div style={{ height: 'auto', overflowY: 'auto' }}><ContaCorrente/></div>
  }    
];

const ContaCorrenteTabs = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [listaContaCorrente, setListaContaCorrente] = useState<conta[]>([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);

  const next = async () => {
    setCurrent(current + 1);
  }

  const prev = () => {
    setCurrent(current - 1);
  };

  const done = async () => {
    if (current === 4) {
      await fetchAccounts();
      const hasRecords = listaContaCorrente.length === 0;            
      if (hasRecords){
        message.error('É necessário ter pelo menos uma conta corrente para o funcionamento do sistema.');
        return;
      }
    }

    // se conta corrente vazia aparece mensagem ok.
    // se cadastrei a conta corrente, ele mostra no grid o cadastro mas preciso clicar duas vezes no next para ele seguir.
    // pensar em uma forma de melhorar isso.
    message.success('Processo completo!');
    setLoading(false);
    await fetchAccounts();
  }

  const itemsSteps = steps.map((item) => ({ 
    key: item.title, 
    title: item.title, 
  }));

  useEffect(() => {
    fetchAccounts();
  }, [activeTab, loading]);

  const fetchAccounts = async () => {
    try {      
      const response = await getContasCorrentes(true);   
        const contas: conta[] = []
        response.data.data.map((conta: contaCorrente) => {
          contas.push({
            value: conta.id,
            label: `${conta.descricao} (${conta.numero})`,
          })      
        })   
        setListaContaCorrente(contas);
        setActiveTab(contas[0].value.toString());
    }
    catch (error){
      console.clear()
      setLoading(true);
    }
  }  

  const handleTabChange = (key: SetStateAction<string>) => {
    setActiveTab(key);
  };

  const items = listaContaCorrente.map((conta: any) => ({
    key: conta.value,
    label: conta.label,
    children: 
    <>
      <Saldo contaCorrenteId={conta.value}/>      
    </>
  }));

  return (
    <>
      {loading ? (
        <>
          <Steps current={current} items={itemsSteps} />          
          <div className="buttons-container" style={{ marginTop: '20px' }}>
            {current < steps.length - 1 && (
              <Button className="btn-primary" type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button className="btn-primary" type="primary" onClick={() => done()}>
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
          <div>{steps[current].content}</div>
          
        </>
      ) : (
        <Content className="content" style={{ padding: '1px', minHeight: '100vh', overflowY: 'hidden'}}> 
          <Tabs defaultActiveKey={activeTab} items={items} onChange={handleTabChange} />
        </Content>
      )}
    </>
  )
};

const Dashboard: React.FC = () => {
  return (
    <>
    <h1>Dashboard</h1>
    <ContaCorrenteTabs />
    </>
  )
}

export default Dashboard