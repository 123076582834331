import { Form, Space, Table, Tooltip } from "antd";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getMovimentoEmAberto, postMovimento, putMovimento } from "../../shared/services/api";

interface Moviment {
  id: number;
  dataMovimento: Date;
  dataPagamento: Date;
  historico: string;
  valor: number;
  percentual: number;
  contaId: number;
  contaCodigo: string;
  contaDescricao: string;
}

const TableMovimentoAberto = (props: { contaCorrenteId: number }) => {
  const [movimentos, setMovimentos] = useState<Moviment[]>([]);
  const [competencia, setCompetencia] = useState(dayjs(new Date()).startOf('month'));
  const [, setEditingMoviment] = useState<Moviment | null>(null);
  const [refreshKey, setRefreshKey] = useState(false);
  const [saldoKey, setSaldoKey] = useState(0);

  useEffect(() => {
    fetchMoviments();
  }, [competencia, refreshKey, saldoKey]);

  const fetchMoviments = async () => {
    const response = await getMovimentoEmAberto(props.contaCorrenteId);
    const movimentosAbertos = response.data.data.filter((mov: Moviment) => dayjs(mov.dataPagamento).format("DD/MM/YYYY") === "01/01/1901");
    setMovimentos(movimentosAbertos);
  };

  const movimentoToBodyMovimento = (movimento: Moviment) => {
    const { id, dataMovimento, dataPagamento, historico, valor, percentual, contaId } = movimento;
    const contaCorrenteId = props.contaCorrenteId;

    return {
      id,
      dataMovimento,
      dataPagamento,
      historico,
      contaId,
      contaCorrenteId,
      valor,
      percentual,
    };
  };

  const handleEditMoviment = async (movimentoId: number) => {
    const movimento = movimentos.find(mov => mov.id === movimentoId);
    setEditingMoviment(movimento || null);
  };

  const saveMovimento = async (movimento: Moviment) => {
    const lancamento = movimentoToBodyMovimento(movimento);
    lancamento.percentual = 0;
    if (movimento.id && movimento.id !== 0) {
      await putMovimento(lancamento);
    } else {
      try {
        await postMovimento(lancamento);
      } catch (error) {
        console.error('Error creating account transaction : ', error);
      }
    }
    fetchMoviments();
    setSaldoKey(saldoKey + 1);
  };

  const handleCellClick = async (record: Moviment) => {
    if (dayjs(record.dataPagamento).format("DD/MM/YYYY") === "01/01/1901") {
      const updatedMovimento = { ...record, dataPagamento: new Date() };
      await saveMovimento(updatedMovimento);
      fetchMoviments();
    }
  };

  const configClassName = (record: Moviment) =>
    record.valor >= 0 ? "cor-positiva" : "cor-negativa";

  const configRenderValue = (value: number) => {
    return <span>{value.toFixed(2)}</span>;
  };

  const configRenderDate = (value: Date, record: Moviment) => {
    return (
      <Tooltip title="Clique para atualizar a data do pagamento">
        <span onClick={() => handleCellClick(record)} style={{ cursor: 'pointer' }}>
          {dayjs(value).format("DD/MM/YYYY") === "01/01/1901" ? "" : dayjs(value).format("DD/MM/YYYY")}
        </span>
      </Tooltip>
    );
  };

  const configRenderActions = (value: number): React.ReactNode => {
    return (
      <Space>
        <a onClick={() => handleEditMoviment(value)}>Editar</a>
      </Space>
    );
  };

  const calculateTotal = () => {
    return movimentos.reduce((total, mov) => total + mov.valor, 0);
  };

  return (
    <>
      <Form>
        <Table
          dataSource={movimentos}
          rowClassName={configClassName}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={5} align="right">
                <b>Total</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} align="right">
                <b>{calculateTotal().toFixed(2)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} />
            </Table.Summary.Row>
          )}
        >
          <Column
            align="center"
            title="Data Movimento"
            dataIndex="dataMovimento"
            key="dataMovimento"
            render={configRenderDate}
          />
          <Column
            align="center"
            title="Data Pagamento"
            dataIndex="dataPagamento"
            key="dataPagamento"
            render={configRenderDate}
          />
          <Column
            title="Historico"
            dataIndex="historico"
            key="historico"
          />
          <Column
            align="right"
            title="Conta"
            dataIndex="contaCodigo"
            key="contaCodigo"
          />
          <Column
            title="Descrição"
            dataIndex="contaDescricao"
            key="contaDescricao"
          />
          <Column
            align="right"
            title="Valor"
            dataIndex="valor"
            key="valor"
            render={configRenderValue}
          />          
        </Table>
      </Form>
    </>
  );
};

export default TableMovimentoAberto;
