import { Button, Card, Col, Layout, Row, notification } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postQueroSerPro } from '../../shared/services/api';
import TermsOfUseModal from './termsOfUseModal';

const UserComparison = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleContinueFreePlan = () => {
    navigate("/");
  };

  const handleBecomePro = async () => {
    try {      
      await postQueroSerPro();      
    } catch (error) {
      notification.error({message: 'Erro', description: 'Erro ao enviar o email.'})
    }
  };

  const showTermsOfUseModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="user-comparison" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <h1 style={{ textAlign: 'center', backgroundColor: '#282832', color: 'white', padding: '10px 0' }}>
        Porque ser um Finpes PRO?
      </h1>
      <Layout style={{ flex: 1 }}>
        <Content style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
          <Row gutter={[20, 20]} style={{ height: '100%', width: '100%', maxWidth: '1200px', justifyContent: 'center' }}>
            <Col xs={24} sm={12} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
              <Card
                hoverable
                title={<span style={{ fontWeight: 'bold' }}>Usuário Grátis</span>}
                style={{ flex: 1, minHeight: '300px', maxWidth: '500px', display: 'flex', flexDirection: 'column' }}
                bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
              >
                <Button size="large" onClick={handleContinueFreePlan} style={{ width: '100%', marginBottom: '20px' }}>
                  Quero continuar com meu plano
                </Button>
                <div style={{ textAlign: 'left' }}>
                  <p>⚠️ Agências: 1</p>
                  <p>⚠️ Conta Corrente: 1</p>
                  <p>⚠️ Plano de Contas: 1</p>
                  <p>⚠️ Lançamentos Mensais: 10</p>
                  <p>⚠️ Relatório DFC: Não Disponível</p>
                  <p>⚠️ Módulo de Cartão de Crédito: Não Disponível</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
              <Card
                hoverable
                title={<span style={{ fontWeight: 'bold' }}>Usuário PRO (R$ 14,99/mês ou R$ 169,00/ano)</span>}
                style={{ flex: 1, minHeight: '300px', maxWidth: '500px', display: 'flex', flexDirection: 'column' }}
                bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
              >
                <Button className='btn-primary' size="large" type="primary" onClick={handleBecomePro} style={{ width: '100%', marginBottom: '20px' }}>
                  Quero ser PRO
                </Button>
                <div style={{ textAlign: 'left' }}>
                  <p style={{ color: '#282832', fontWeight: 'bold' }}>✅ Agências: Indefinido</p>
                  <p style={{ color: '#282832', fontWeight: 'bold' }}>✅ Conta Corrente: Indefinido</p>
                  <p style={{ color: '#282832', fontWeight: 'bold' }}>✅ Plano de Contas: Indefinido</p>
                  <p style={{ color: '#282832', fontWeight: 'bold' }}>✅ Lançamentos Mensais: Indefinido</p>
                  <p style={{ color: '#282832', fontWeight: 'bold' }}>✅ Relatório DFC: Disponível</p>
                  <p style={{ color: '#282832', fontWeight: 'bold' }}>✅ Módulo de Cartão de Crédito: Indefinido</p>
                </div>
                <Button type="link" onClick={showTermsOfUseModal}>
                  Termos de Uso
                </Button>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
      <TermsOfUseModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
}

export default UserComparison;
