import { Button, Form, Input, Modal, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { getAgencias, getBancos, getMoedas, getPlanoContas } from "../../shared/services/api";

interface ContaCorrente {
  id: number;
  numero: string;
  descricao: string;
  moeda: string;
  status: string;
  statusTelaPrincipal: boolean;
  agenciaId: number;
  agenciaNome: string;
  bancoId: number;
  bancoNome: string;
  planoContaId: number;
  planoContaNome: string;
}

interface ContaCorrenteModalProps {
  editingAccount: ContaCorrente | null;
  open: boolean;
  onCancel: () => void;
  onOk: (novaContaCorrente: ContaCorrente) => void;
}

interface Moeda {
  id: number;
  codigo: string;
  nome: string;
}

interface MoedaCombo {
  value: string,
  label: string
}

interface Banco {
  id: number;
  codigo: string;
  nome: string;
}

interface BancoCombo {
  value: number,
  label: string
}

interface Agencia {
  id: number;
  codigo: string;
  nome: string;
}

interface AgenciaCombo {
  value: number,
  label: string
}

interface Plano {
  id: number;
  codigo: string;
  nome: string;
}

interface PlanoCombo {
  value: number,
  label: string
}

const ContaCorrenteModal = ({editingAccount, open, onCancel, onOk}: ContaCorrenteModalProps) => {
  const [form] = Form.useForm();
  const [listaMoedas, setMoedas] = useState<MoedaCombo[]>([]);
  const [, setSelectedMoeda] = useState<string>("");
  const [listaBancos, setBancos] = useState<BancoCombo[]>([]);
  const [, setSelectedBanco] = useState<number | undefined>(undefined);
  const [listaAgencias, setAgencias] = useState<AgenciaCombo[]>([]);
  const [, setSelectedAgencia] = useState<number | undefined>(undefined);
  const [listaPlanoConta, setPlanoContas] = useState<PlanoCombo[]>([]);
  const [, setSelectedPlano] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState<boolean>(true);
  const [statusTelaPrincipal, setStatusTelaPrincipal] = useState<boolean>(true);
  
  useEffect(() => {
    if (editingAccount) {      
      form.setFieldsValue({...editingAccount, status: editingAccount.status === "A",});
      setStatus(editingAccount.status === "A");
      setStatusTelaPrincipal(editingAccount.statusTelaPrincipal);
    } else {
      form.resetFields();
      setStatus(true);
      setStatusTelaPrincipal(true);
    }
    fetchMoedas();
    fetchBancos();
    fetchAgencias();
    fetchPlanoContas();
  }, [editingAccount]);

  const fetchMoedas = async () => {
    const response = await getMoedas();

  const moedas: MoedaCombo[] = []
    response.data.data.map((moeda: Moeda) => {
      moedas.push({
        value: moeda.codigo,
        label: `${moeda.codigo}`
      })
    })
    setMoedas(moedas);
  }

  const fetchBancos = async () => {
    const response = await getBancos();

    const bancos: BancoCombo[] = []
    response.data.data.map((banco: Banco) => {
      bancos.push({
        value: banco.id,
        label: `${banco.codigo} - ${banco.nome}`
      })
    })
    setBancos(bancos);
  }

  const fetchAgencias = async () => {
    const response = await getAgencias();

    const agencias: AgenciaCombo[] = []
    response.data.data.map((agencia: Agencia) => {
      agencias.push({
        value: agencia.id,
        label: `${agencia.codigo} - ${agencia.nome}`
      })
    })
    setAgencias(agencias);
  }

  const fetchPlanoContas = async () => {
    const response = await getPlanoContas();

    const planos: PlanoCombo[] = []
    response.data.data.map((plano: Plano) => {
      planos.push({
        value: plano.id,
        label: `${plano.nome}`
      })
    })
    setPlanoContas(planos);
  }

  const handleMoedaChange = (value: string) => {
    setSelectedMoeda(value);
  }
  
  const handleBancoChange = (value: undefined) => {
    setSelectedBanco(value);
  }

  const handleAgenciaChange = (value: undefined) => {
    setSelectedAgencia(value);
  }

  const handlePlanoContaChange = (value: undefined) => {
    setSelectedPlano(value);
  }

  const handleSave = () => {    
    form.setFieldsValue({
      status: status ? "A" : "I",
    });
           
    form.validateFields()
      .then((values) => {
        onOk(values);        
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.log('Erro ao salvar conta corrente:', errorInfo);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  }

  
  const handleStatusChange = (checked: boolean) => {
    setStatus(checked);
    form.setFieldsValue({
      status: checked ? "A" : "I",
    });
  };

  const handleStatusTelaPrincipalChange = (checked: boolean) => {
    setStatusTelaPrincipal(checked);
    form.setFieldsValue({
      statusTelaPrincipal: checked,
    });
  };

  const isEditing = !!editingAccount;

  return (
    <Modal
      centered
      closeIcon
      open={open}
      title={isEditing ? "Editar Conta Corrente" : "Nova Conta Corrente"}  
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button className="btn-primary" key="save" type="primary" onClick={handleSave}>
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} labelCol={{ flex: '150px' }}
        labelAlign="left"
        labelWrap
        colon={false}
        wrapperCol={{ flex: 1 }}
        style={{ maxWidth: 600 }}
        initialValues={{
          status: "A",
          statusTelaPrincipal: true
        }}>          
        <Form.Item name="id" hidden/>
          <Form.Item label="Número" name="numero" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        <Form.Item label="Descrição" name="descricao" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Moeda" name="moeda" rules={[{ required: true }]}>
          <Select onChange={handleMoedaChange} options={listaMoedas}/>
        </Form.Item>
        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
          <Switch checked={status} checkedChildren="Ativo" unCheckedChildren="Inativo" onChange={handleStatusChange}/>
        </Form.Item>
        <Form.Item label="Tela Principal" name="statusTelaPrincipal" rules={[{ required: true }]}>
          <Switch checked={statusTelaPrincipal} checkedChildren="Habilitado" unCheckedChildren="Desabilitado" onChange={handleStatusTelaPrincipalChange}/>
        </Form.Item>
        <Form.Item label="Banco" name="bancoId" rules={[{ required: true }]}>
          <Select onChange={handleBancoChange} options={listaBancos}/>
        </Form.Item>
        <Form.Item label="Agencia" name="agenciaId" rules={[{ required: true }]}>
          <Select onChange={handleAgenciaChange} options={listaAgencias}/>
        </Form.Item>
        <Form.Item label="Plano de Conta" name="planoContaId" rules={[{ required: true }]}>
            <Select onChange={handlePlanoContaChange} options={listaPlanoConta}/>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ContaCorrenteModal;