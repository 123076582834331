/* eslint-disable react/prop-types */
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tag } from "antd";
import { AlignType } from "rc-table/lib/interface";
import { useEffect, useState } from "react";
import { getContas, getContasCorrentes } from "../../shared/services/api";
import { CreditCard } from "./interfaces/CreditCard";

interface CartaoCreditoTableProps {
  data: CreditCard[];
  onDelete: (id: number) => void;
  onUpdate: (updatedData: CreditCard) => void;
  onCreate: (newData: CreditCard) => void;
}

interface ContaCombo {
  value: number,
  label: string
}

interface Conta {
  id: number,
  codigo: string,
  descricao: string
}

const TableCartaoCredito: React.FC<CartaoCreditoTableProps> = ({ data, onDelete, onUpdate, onCreate }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [accountId, setSelectedAccount] = useState<number | undefined>(undefined);
  const [listaConta, setListaConta] = useState<ContaCombo[]>([]);
  const [listaContaCorrente, setListaContaCorrente] = useState([]);
  const [checkingaccountId, setSelectedCheckingAccount] = useState(0);

  const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360); 
    const pastelLightness = 70 + Math.floor(Math.random() * 10); 
    const pastelSaturation = 40 + Math.floor(Math.random() * 10); 

    return `hsl(${hue}, ${pastelSaturation}%, ${pastelLightness}%)`; 
  };

  const columns = [
    {
      title: 'Descricao',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Bandeira',
      dataIndex: 'bandeira',
      key: 'bandeira',
      render: (_text: string, record: CreditCard) => {
        const color = getRandomColor();
        const tag = record.bandeira;

        return (<Tag color={color} key={tag}>{tag}</Tag>);
      },
    },
    {
      title: 'Vencimento',
      dataIndex: 'diaVencimento',
      key: 'diaVencimento',
      align: 'center' as AlignType
    },
    {
      title: 'Limite de Crédito',
      dataIndex: 'limite',
      key: 'limite',
      align: 'right' as AlignType,
      render: (_text: string, record: CreditCard) => (
          <span>{record.limite.toFixed(2)}</span>
        )
    },
    {
      title: 'Conta Corrente',
      dataIndex: 'contaCorrenteDescricao',
      key: 'contaCorrenteDescricao',
      render: (_text: string, record: CreditCard) => (
        <span>{`${record.contaCorrenteNumero} - ${record.contaCorrenteDescricao}`}</span>
      ),
    },
    {
      title: 'Conta Vinculada',
      dataIndex: 'contaDescricao',
      key: 'contaDescricao',
      render: (_text: string, record: CreditCard) => (
        <span>{`${record.contaCodigo} - ${record.contaDescricao}`}</span>
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_text: string, record: CreditCard) => ((
        <Space>
          <a onClick={() => handleEdit(record)}>Editar</a>
          <Popconfirm title="Deletar Cartão de Crédito?" onConfirm={() => onDelete(record.id)}>
            <a>Deletar</a>
          </Popconfirm>
        </Space>
      )),
    },
  ];

  useEffect(() => {
    fetchAccounts(checkingaccountId);
  }, [checkingaccountId]);

  useEffect(() => {
    fetchCheckingAccounts();
  }, []);

  const fetchAccounts = async (checkingaccountId: number) => {
    const response = await getContas({contaCorrenteId: checkingaccountId, tipoConta: "S"});  
    const contas: ContaCombo[] = []
    response.data.data.map((conta: Conta) => {
      contas.push({
        value: conta.id,
        label: `${conta.codigo} - ${conta.descricao}`,
      })
    })
    setListaConta(contas);
  }

  const fetchCheckingAccounts = async () => {
    const response = await getContasCorrentes(false);

    const contas: any = []
    response.data.data.map((conta: any) => {
      contas.push({
        value: conta.id,
        label: `${conta.numero} - ${conta.descricao}`,
      })
    })
    setListaContaCorrente(contas);
  }

  const handleEdit = (record: CreditCard) => {
    form.setFieldsValue({
      ...record,        
    });
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    const isEditing = !!form.getFieldValue("id");
    const fieldsValue = await form.validateFields();

    const record: CreditCard = {
      ...fieldsValue,
      id: isEditing ? form.getFieldValue('id') : undefined,
    };

    if (isEditing) {
      onUpdate(record);
    } else {
      onCreate(record);
    }
  
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCreate = () => {    
    form.resetFields();
    setSelectedCheckingAccount(0);
    setIsModalVisible(true);
  };

  const handleAccountChange = (value: undefined) => {
    setSelectedAccount(value);
  }

  const handleCheckingAccountChange = (value: number) => {
    setSelectedCheckingAccount(value);
    fetchAccounts(value);
  }

  const isEditing = !!form.getFieldValue('id');

  return (
    <div>
      <Button onClick={handleCreate}>Adicionar Cartao Crédito</Button>
      <Table dataSource={data} columns={columns} />

      <Modal 
        title={isEditing ? "Editar Cartão de Crédito" : "Novo Cartão de Crédito"} 
        open={isModalVisible}        
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancelar
          </Button>,
          <Button className="btn-primary" key="save" type="primary" onClick={handleModalOk}>
            Salvar
          </Button>,
        ]}>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="contaCorrenteId"
                label="Conta Corrente"
                rules={[{ required: true, message: 'Informe a conta corrente' }]}
              >
                <Select
                  placeholder="Selecione a conta corrente"
                  options={listaContaCorrente}
                  onChange={handleCheckingAccountChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Descrição"
                name="descricao"
                rules={[{ required: true, message: 'Informe a descrição' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Bandeira"
                name="bandeira"
                rules={[{ required: true, message: 'Informe a bandeira' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="contaId"
                label="Conta"
                rules={[{ required: true, message: 'Informe a conta' }]}
              >
                <Select
                  placeholder="Selecione a conta"
                  options={listaConta}
                  onChange={handleAccountChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="limite"
                label="Limite de Crédito"
                rules={[{ required: true, message: 'Informe o valor' }]}
              >
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="diaVencimento"
                label="Dia de Vencimento"
                rules={[{ required: true, message: 'Informe o dia de vencimento' }]}
              >
                <InputNumber min={1} max={31} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default TableCartaoCredito