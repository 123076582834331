import { Button, DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getContas } from "../../shared/services/api";

interface Conta {
  id: number,
  codigo: string,
  descricao: string
}

interface ContaCombo {
  value: number,
  label: string
}

interface Movimento {
  id: number;
  dataMovimento: Date;
  dataPagamento: Date;
  historico: string;
  valor: number;
  percentual: number;
  contaId: number;
  contaCodigo: string;
  contaDescricao: string;
}

interface MovimentoModalProps {
  contaCorrenteId: number;
  open: boolean;
  onCancel: () => void;
  onOk: (novoLancamento: Movimento) => void;
  editingMovimento: Movimento | null;
}

const MovimentoModal = ({contaCorrenteId, editingMovimento, open, onCancel, onOk}: MovimentoModalProps) => {
  const [form] = Form.useForm();
  const [, setSelectedAccount] = useState<number | undefined>(undefined);
  const [listaContaCorrente, setListaContaCorrente] = useState<ContaCombo[]>([]);

  useEffect(() => {
    if (editingMovimento) {
      const dataMovimento = dayjs(editingMovimento.dataMovimento); 
      const dataPagamento = editingMovimento.dataPagamento === undefined ? dayjs(editingMovimento.dataPagamento) : null;
      form.setFieldsValue({...editingMovimento, dataMovimento, dataPagamento}); 
    } else {
      form.resetFields();
    }
    fetchAccounts();
  }, [editingMovimento]);

  const fetchAccounts = async () => {
    const response = await getContas({contaCorrenteId, tipoConta: "A"});

    const contas: ContaCombo[] = []
    response.data.data.map((conta: Conta) => {
      contas.push({
        value: conta.id,
        label: `${conta.codigo} - ${conta.descricao}`,
      })
    })
    setListaContaCorrente(contas);
  }

  const handleAccountChange = (value: undefined) => {
    setSelectedAccount(value);
  }

  const handleSave = () => { 
    form.validateFields()
      .then((values) => {
        onOk(values);        
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.log('Erro ao salvar o lançamento:', errorInfo);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  }

  const isEditing = !!editingMovimento;

  return (
    <Modal
      centered
      closeIcon
      open={open}
      title={isEditing ? "Editar Lançamento" : "Novo Lançamento"}  
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button className="btn-primary" key="save" type="primary" onClick={handleSave}>
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} labelCol={{ flex: '150px' }}
      labelAlign="left"
      labelWrap
      colon={false}
      wrapperCol={{ flex: 1 }}
      style={{ maxWidth: 600 }}>
        <Form.Item name="id"/>
        <Form.Item name="dataMovimento" label="Data Movimento" rules={[{ required: true, message: 'Informe a data de movimento' }]}>
          <DatePicker format={"DD/MM/YYYY"}/>
        </Form.Item>
        <Form.Item name="dataPagamento" label="Data Pagamento" rules={[{ required: false, message: 'Informe a data de pagamento' }]}>
          <DatePicker format={"DD/MM/YYYY"}/>
        </Form.Item>
        <Form.Item name="contaId" label="Conta" rules={[{ required: true, message: 'Informe a conta' }]}>
          <Select onChange={handleAccountChange} options={listaContaCorrente}/>
        </Form.Item>
        <Form.Item name="historico" label="Histórico" rules={[{ required: true, message: 'Informe o histórico' }]}>
          <Input.TextArea />
        </Form.Item>        
        <Form.Item name="valor" label="Valor" rules={[{ required: true, message: 'Informe o valor' }]}>
          <InputNumber 
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, '') : "")}/>
        </Form.Item>                     
      </Form>
    </Modal>
  );
};

export default MovimentoModal;