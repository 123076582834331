/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { Navigate, Route, Routes } from "react-router-dom";
import Banco from "./pages/Banco";
import ContaCorrente from "./pages/ContaCorrente";
import Dashboard from "./pages/Dashboard";
import Dfc from "./pages/Dfc";
import Login from "./pages/Login";
import Moeda from "./pages/Moeda";
import Movimento from "./pages/Movimento";
import NotFound from "./pages/NotFound";
import Perfil from "./pages/Perfil";
import PlanAccount from "./pages/PlanoConta";
import Welcome from "./pages/Welcome";
import UserComparison from "./pages/Welcome/userComparison";
import MovimentoEmAberto from "./pages/MovimentoEmAberto";

const RequireAuth = ({ children, redirectTo}: any) => {
  const authenticated = !! localStorage.getItem('token');
  
  return authenticated ? children : <Navigate to={redirectTo}/>;
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login/>}/>
      <Route path="/" element={<RequireAuth redirectTo="/login"><Welcome/></RequireAuth>}/>
      <Route path="*" element={<RequireAuth redirectTo="/login"><NotFound/></RequireAuth>}/>
      <Route path="/perfil" element={<RequireAuth redirectTo="/login"><Perfil/></RequireAuth>}/>
      <Route path="/dfc" element={<RequireAuth redirectTo="/login"><Dfc/></RequireAuth>}/>
      <Route path="/movimento" element={<RequireAuth redirectTo="/login"><Movimento/></RequireAuth>}/>
      <Route path="/movimentoEmAberto" element={<RequireAuth redirectTo="/login"><MovimentoEmAberto/></RequireAuth>}/>
      <Route path="/dashboard" element={<RequireAuth redirectTo="/login"><Dashboard/></RequireAuth>}/>
      <Route path="/moeda" element={<RequireAuth redirectTo="/login"><Moeda/></RequireAuth>}/>
      <Route path="/banco" element={<RequireAuth redirectTo="/login"><Banco/></RequireAuth>}/>
      <Route path="/contaCorrente" element={<RequireAuth redirectTo="/login"><ContaCorrente/></RequireAuth>}/>
      <Route path="/planoConta" element={<RequireAuth redirectTo="/login"><PlanAccount/></RequireAuth>}/>
      <Route path="/userComparison" element={<RequireAuth redirectTo="/login"><UserComparison/></RequireAuth>}/>
    </Routes>
  );
}

export default AppRoutes;