import { IBodyAgencia, IBodyAuth, IBodyBanco, IBodyCartaoCredito, IBodyConta, IBodyContaCorrente, IBodyContaPlanoConta, IBodyForgot, IBodyGetMovimento, IBodyMoeda, IBodyMovimento, IBodyPassword, IBodyPessoa, IBodyPlanoConta, IBodyRegister, IBodySaldo } from "../interfaces";
import instance from "./axios.config";

export const login = async (body: IBodyAuth) => {
  return instance.post("/Autenticacao", body);
}

export const postEmail = async (body: IBodyForgot) => {
  return instance.post("/Autenticacao/EsqueciSenha", body);
}

export const postRegister = async (body: IBodyRegister) => {
  return instance.post("/Autenticacao/Register", body);
}

export const putPassword = async (body: IBodyPassword) => {
  console.log("body: ", body);
  return instance.put("/Autenticacao/UpdatePassword", body);
}

export const logout = async () => {
  return localStorage.clear();
}

export const getSaldoAnual = async (body: IBodySaldo) => {
  return instance.get("Saldo/DFC_Anual", {
    params: {
      contaCorrenteId: body.contaCorrenteId,
      competencia: body.competencia.toLocaleDateString(),
      provisao: body.provisao
    }
  });
}

export const getSaldos = async (contaCorrenteId: number, provisao: boolean) => {
  return instance.get("/Saldo/Por", {
    params: {
      contaCorrenteId: contaCorrenteId,
      provisao: provisao
    }
  })
}

export const getSaldoReceita = async (body: IBodySaldo) => {
  return instance.get("Saldo/Receita", {
    params: {
      contaCorrenteId: body.contaCorrenteId,
      competencia: body.competencia.toLocaleDateString(),
      provisao: body.provisao
    }
  });
}

export const getSaldoDespesa = async (body: IBodySaldo) => {  
  return instance.get("Saldo/Despesa", {
    params: {
      contaCorrenteId: body.contaCorrenteId,
      competencia: body.competencia.toLocaleDateString(),
      provisao: body.provisao
    }
  });
}

export const getSaldoReceitaDespesa = async (contaCorrenteId: number, ano: number) => {
  return instance.get("Saldo/ReceitaDespesaAno", {
    params: {
      contaCorrenteId: contaCorrenteId,
      ano: ano
    }
  });
}

export const getSintetico = async (contaCorrenteId: number, competencia: Date) => {
  return instance.get("Saldo/Sintetico", {
    params: {
      contaCorrenteId: contaCorrenteId,
      competencia: competencia
    }
  });
}

export const getMovimentos = async (body: IBodyGetMovimento) => {  
  return instance.get("/Movimento/ObterPor", { 
    params: {
      ContaCorrenteId: body.contaCorrenteId,
      CompetenciaInicial: body.competenciaInicial.toLocaleDateString(),
      CompetenciaFinal: body.competenciaFinal.toLocaleDateString()
    }
  });
};

export const getMovimento = async (id: number) => {
  return instance.get(`/Movimento/ObterPorId?id=${id}`)
}

export const getMovimentoEmAberto = async (contaCorrenteId: number) => {
  return instance.get(`/Movimento/EmAberto?contaCorrenteId=${contaCorrenteId}`)
}

export const getContas = async (body: IBodyConta) => {
  return instance.get("/Conta/ObterTodos", {
    params: {
      contaCorrenteId: body.contaCorrenteId,
      tipoConta: body.tipoConta
    }
  });
}

export const postMovimento = async (body: IBodyMovimento) => {
  return instance.post("/Movimento", body);
}

export const putMovimento = async (body: IBodyMovimento) => {
  return instance.put("/Movimento", body);
}

export const deleteMovimento = async (id: number) => {
  return instance.delete(`/Movimento?id=${id}`)
}

export const getMoedas = async () => {
  return instance.get("/Moeda/ObterTodos")
}

export const deleteMoeda = async (id: number) => {
  return instance.delete(`/Moeda?id=${id}`)
}

export const putMoeda = async (body: IBodyMoeda) => {
  return instance.put("/Moeda", body);
}

export const postMoeda = async (body: IBodyMoeda) => {
  return instance.post("/Moeda", body);
}

export const getBancos = async () => {
  return instance.get("/Banco/ObterPor")
}

export const deleteBanco = async (id: number) => {
  return instance.delete(`/Banco?id=${id}`)
}

export const putBanco = async (body: IBodyBanco) => {
  return instance.put("/Banco", body);
}

export const postBanco = async (body: IBodyBanco) => {
  return instance.post("/Banco", body);
}

export const getAgencias = async () => {
  return instance.get("/Agencia/ObterPor")
}

export const deleteAgencia = async (id: number) => {
  return instance.delete(`/Agencia?id=${id}`)
}

export const putAgencia = async (body: IBodyAgencia) => {
  return instance.put("/Agencia", body);
}

export const postAgencia = async (body: IBodyAgencia) => {
  return instance.post("/Agencia", body);
}

export const getContasCorrentes = async (status:boolean) => {
  if (status){
    return instance.get(`/ContaCorrente/ObterPor?statusTelaPrincipal=${status}`);
  } else {
    return instance.get("ContaCorrente/ObterPor");
  }
}

export const getContaCorrente = async (contaCorrenteId: number) => {
  return instance.get(`/ContaCorrente/ObterPorId?Id=${contaCorrenteId}`)
}

export const deleteContaCorrente = async (id: number) => {
  return instance.delete(`/ContaCorrente?id=${id}`)
}

export const putContaCorrente = async (body: IBodyContaCorrente) => {
  return instance.put("/ContaCorrente", body);
}

export const postContaCorrente = async (body: IBodyContaCorrente) => {
  return instance.post("/ContaCorrente", body);
}

export const getPlanoContas = async () => {
  return instance.get("/PlanoConta/ObterTodos")
}

export const deletePlanoConta = async (id: number) => {
  return instance.delete(`/PlanoConta?id=${id}`)
}

export const putPlanoConta = async (body: IBodyPlanoConta) => {
  return instance.put("/PlanoConta", body);
}

export const postPlanoConta = async (body: IBodyPlanoConta) => {
  return instance.post("/PlanoConta", body);
}

export const getPlanoConta = async (planoContaId: number) => {
  return instance.get(`/PlanoConta/ObterPorId?Id=${planoContaId}`)
}

export const getContasPlano = async (planoContaId: number) => {
  return instance.get(`/Conta/ObterTodosPor?planoContaId=${planoContaId}`)
}

export const postConta = async (body: IBodyContaPlanoConta) => {
  return instance.post("/Conta", body);
}

export const putConta = async (body: IBodyContaPlanoConta) => {
  return instance.put("/Conta", body);
}

export const deleteConta = async (id: number) => {
  return instance.delete(`/Conta?id=${id}`)
}

export const getConta = async (contaId: number) => {
  return instance.get(`/Conta/ObterPor?Id=${contaId}`)
}

export const getCartaoCredito = async (contaId: number) => {
  return instance.get(`/CartaoCredito/ObterPor?Id=${contaId}`)
}

export const getAllCartaoCredito = async () => {
  return instance.get(`/CartaoCredito/ObterTodos`)
}

export const deleteCartaoCredito = async (id: number) => {
  return instance.delete(`/CartaoCredito?id=${id}`)
}

export const putCartaoCredito = async (body: IBodyCartaoCredito) => {
  return instance.put("/CartaoCredito", body);
}

export const postCartaoCredito = async (body: IBodyCartaoCredito) => {
  return instance.post("/CartaoCredito", body);
}

export const getPessoa = async () => {
  return instance.get("Pessoa/ObterPor");
}

export const putPessoa = async (body: IBodyPessoa) => {
  return instance.put("Pessoa", body);
}

export const postQueroSerPro = async () => {
  return instance.post("/Pessoa/QueroSerPro");
}