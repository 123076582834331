import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { deleteAgencia, getAgencias, postAgencia, putAgencia } from "../../shared/services/api";
import { Agency } from "./interfaces/Agency";
import TableAgencia from "./tableAgencia";

const Agencia = () => {
  const [data, setData] = useState<Agency[]>([]);

  useEffect(() => {
    fetchAgencias();
  }, []);

  const fetchAgencias = async () => {
    const response = await getAgencias();
    setData(response.data.data);
  }

  const handleDelete = async (moedaId: number) => {   
    await deleteAgencia(moedaId);
    fetchAgencias();
  };

  const handleUpdate = async (updatedData: Agency) => {    
    await putAgencia(updatedData);
    fetchAgencias();
  };

  const handleCreate = async (newData: Agency) => {
    try {
      await postAgencia(newData);
      fetchAgencias();
    } catch (error) {
      console.error('Error creating agency: ', error);
    }
  };

  return (    
    <Content className="content"> 
      <h1>Agencias</h1>      
      <TableAgencia data={data} onDelete={handleDelete} onUpdate={handleUpdate} onCreate={handleCreate} />      
    </Content>  
  )
}

export default Agencia